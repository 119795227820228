import { createApp } from "vue";
import App from "./App.vue";
import "@/assets/style.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import { createPinia } from "pinia";
import router from "./router";

const clickOutside = {
  mounted(el, binding) {
    binding.event = (e)=>{
        if (!(e.target == el || el.contains(e.target))){
            binding.value(e);
        }
    }
    document.body.addEventListener('click',binding.event);
  },
  unmounted(el, binding) {
    document.body.removeEventListener('click',binding.event);
  },
};

const app = createApp(App);

app.directive("click-outside", clickOutside);
app.use(createPinia());
app.use(router);
app.mount("#app");
