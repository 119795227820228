import { indexRequest } from "@/requests/categories";
import { defineStore } from "pinia";

const useCategories = defineStore("categories", {
  state: () => {
    return {
      categories: null,
    };
  },
  actions: {
    async fetchCategories() {
      let response = await indexRequest();
      this.categories = response.data.data.categories;
    },

    addCategory(category) {
      let search = this.categories.findIndex(
        (searchCategory) => searchCategory.id == category.id
      );
      if (search == -1) {
        this.categories = [...this.categories, category];
      }
    },

    editCategory(category) {
      let index = this.categories.findIndex((cate) => cate.id == category.id);
      if (index > -1) {
        this.categories[index] = category;
      }
    },

    removeCategory(id) {
      let index = this.categories.findIndex((cate) => cate.id == id);
      if (index > -1) {
        this.categories = this.categories.filter(
          (category) => category.id != id
        );
      }
    },
  },
});

export default useCategories;
