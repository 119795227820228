<script setup>
import { ref } from "vue";
import useUser from "@/store/user";

const userStore = useUser();
const sidebar = ref(false);
const closeSidebar = () => {
  sidebar.value = false;
};
</script>

<template>
  <div v-click-outside="closeSidebar" class="fixed right-0 top-[65px] z-[1002]">
    <div v-show="!sidebar" @click="sidebar = !sidebar"
      class="absolute bg-palette1-green-4 rounded-l-lg text-center flex flex-col justify-center p-2 bg-opacity-70">
      <i class="bi bi-list-nested text-white text-4xl"></i>
    </div>
    <Transition enter-active-class="transition-all duration-300 origin-right"
      leave-active-class="transition-all duration-300 origin-right" enter-from-class="scale-x-0"
      enter-to-class="scale-x-100" leave-from-class="scale-x-100" leave-to-class="scale-x-0">
      <ul v-show="sidebar"
        class="flex flex-row flex-nowrap items-center bg-palette1-green-4 py-2 px-2 max-h-[60vh] rounded-l-lg overflow-y-auto">
        <div class="mx-2" v-if="userStore && userStore.role == 'Super Admin'">
          <RouterLink class="flex flex-col items-center" :to="{ name: 'admin.users.index' }"><i
              class="bi bi-person-fill text-white text-3xl"></i>
            <span class="text-white font-bold text-sm">کاربران</span>
          </RouterLink>
        </div>
        <div class="mx-2">
          <RouterLink class="flex flex-col items-center" :to="{ name: 'admin.stores.index' }">
            <i class="bi bi-columns-gap text-white text-3xl"></i>
            <span class="text-white font-bold text-sm">انبارها</span>
          </RouterLink>
        </div>
        <div class="mx-2">
          <RouterLink class="flex flex-col items-center" :to="{ name: 'admin.categories.index' }">
            <i class="bi bi-collection-fill text-white text-3xl"></i>
            <span class="text-white font-bold text-sm">دسته بندی</span>
          </RouterLink>
        </div>

        <div class="mx-2">
          <RouterLink class="flex flex-col items-center" :to="{ name: 'admin.products.index' }">
            <i class="bi bi-box text-white text-3xl"></i>
            <span class="text-white font-bold text-sm">محصولات</span>
          </RouterLink>
        </div>

        <div class="mx-2">
          <RouterLink class="flex flex-col items-center" :to="{ name: 'admin.products.index' }">
            <i class="bi bi-ui-checks text-white text-3xl"></i>
            <span class="text-white font-bold text-sm">لیست ها</span>
          </RouterLink>
        </div>

        <div class="mx-2" @click="sidebar = !sidebar">
          <i class="bi bi-arrow-right text-white text-3xl"></i>
        </div>
      </ul>
    </Transition>
  </div>
  <RouterView></RouterView>
</template>
