import Swal from "sweetalert2";

const swal = (type = "success", message = "") => {
  let color;
  switch (type) {
    case "success":
      color = { background: "green", color: "white" };
      break;
    case "warning":
      color = { background: "yellow", color: "white" };
      break;
    case "error":
      color = { background: "red", color: "white" };
      break;
  }
  Swal.fire({
    text: message,
    timer: 3000,
    timerProgressBar: true,
    toast: true,
    icon: type,
    showConfirmButton: false,
    position: "top-start",
    ...color,
  });
};

export default swal;
