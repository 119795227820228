<script setup>
import { defineProps, ref } from "vue";
import swal from "@/swal";
import {deleteUser} from '@/requests/userRequest'

const Props = defineProps(["user", "search", "searchDispatch",'onDelete']);
const dropDown = ref(false);

const closeDropdown = () => {
  dropDown.value = false;
};

const destroyUser = async()=>{
try {
    await deleteUser(Props.user.id);
    Props.onDelete(Props.user.id);
    swal('success','کاریر با موفقیت حذف شد.')
} catch (error) {
    console.log(error)
}
}
</script>

<template>
  <tr>
    <td class="px-4 py-4 text-sm font-medium whitespace-nowrap">
      <div>
        <div
          v-if="Props.searchDispatch && Props.search"
          class="font-medium text-gray-800 dark:text-white"
        >
          <span>
            {{ Props.user.name.slice(0, Props.user.name.search(Props.search)) }}
          </span>
          <span class="bg-green-500">
            {{
              Props.user.name.slice(
                Props.user.name.search(Props.search),
                Props.user.name.search(Props.search) + Props.search.length
              )
            }}
          </span>
          <span>
            {{
              Props.user.name.slice(
                Props.user.name.search(Props.search) + Props.search.length
              )
            }}
          </span>
        </div>
        <span v-else class="font-medium text-gray-800 dark:text-white">
          {{ Props.user.name }}
        </span>
      </div>
    </td>
    <td class="px-12 py-4 text-sm font-medium whitespace-nowrap">
      <div
        class="inline px-3 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 dark:bg-gray-800"
      >
        {{ Props.user.roles["0"].name }}
      </div>
    </td>

    <td class="px-4 py-4 text-sm whitespace-nowrap text-end">
      <button
        class="relative px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg dark:text-gray-300 hover:bg-gray-100"
        @click="dropDown = !dropDown"
        v-click-outside="closeDropdown"
        :id="`userIndexRow_${Props.user.id}`"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
          />
        </svg>
        <Transition
          enter-active-class="transition-all duration-300 origin-bottom-left"
          leave-active-class="transition-all duration-300 origin-bottom-left"
          enter-from-class="scale-0"
          enter-to-class="scale-100"
          leave-from-class="scale-100"
          leave-to-class="scale-0"
        >
          <div
            v-show="dropDown"
            class="absolute p-2 bg-white border-2 border-gray-900 rounded-md flex flex-col min-w-[180px] left-8 bottom-0 z-[1000] overflow-y-auto"
          >
            <RouterLink
              :to="{ name: 'admin.users.edit', params: { id: Props.user.id } }"
              class="w-full px-1 pb-2 flex flex-row justify-between items-center border-b-2 border-gray-300 text-gray-800"
            >
              <span>ویرایش</span>
              <i class="bi bi-gear text-xl"></i>
            </RouterLink>
            <div
            @click="destroyUser"
              class="w-full px-1 pt-2 flex flex-row justify-between items-center text-gray-800"
            >
              <span>پاک کردن</span>
              <i class="bi bi-person-dash text-xl text-red-600"></i>
            </div>
          </div>
        </Transition>
      </button>
    </td>
  </tr>
</template>
