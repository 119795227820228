import Echo from "laravel-echo";
import { baseURL } from "./axios";
import axios from "axios";
import pusher from "pusher-js";
var Pusher = pusher;

const echoAxios = axios.create({
  baseURL: `${baseURL}`,
  headers: {
    Accept: "application/json",
  },
});
echoAxios.defaults.withCredentials = true;

var echo = new Echo({
  broadcaster: "pusher",

  // **************** prod variables *****************
  key:'barzegar',
  wsHost: "websocket.barzegar-shop.ir",
  wsPort: 80,
  wssPort: 443,
  enabledTransports: ['ws','wss'],
  cluster: "eu",
  encrypted: true,
  forceTLS: true,
  // ***************************************

  //***************** dev variables ******************
  // key: "barzegar",
  // wsHost: "127.0.0.1",
  // wsPort: 6001,
  // cluster: "ap1",
  // encrypted: false,
  // forceTLS: false,
  // ******************************************
  disableStats: true,
  authorizer: (channel) => {
    return {
      authorize: (socketId, callback) => {
        echoAxios
          .get(`/sanctum/csrf-cookie`)
          .then(() => {
            echoAxios
              .post(`/broadcasting/auth`, {
                socket_id: socketId,
                channel_name: channel.name,
              })
              .then((response) => {
                callback(false, response.data);
              });
          })
          .catch((error) => {
            callback(true, error);
          });
      },
    };
  },
  //   authEndpoint: `${baseURL}/broadcasting/auth`,
});

Pusher.logToConsole = true;

export default echo;
