import { indexRequest } from "@/requests/storeRequest";
import { defineStore } from "pinia";

const useStore = defineStore("store", {
  state: () => {
    return {
      stores: null,
    };
  },
  actions: {
    async fetchStores() {
      let response = await indexRequest();
      this.stores = response.data.data.stores;
    },
    removeStore(id) {
      let index = this.stores.findIndex((store) => store.id == id);
      if (index != -1) {
        this.stores = this.stores.filter((store) => store.id != id);
      }
    },
    editStore(subStore) {
      let index = this.stores.findIndex((store) => store.id == subStore.id);
      if (index != -1) {
        this.stores[index] = subStore;
      }
    },
    addStore(subStore) {
      let index = this.stores.findIndex((store) => store.id == subStore.id);
      if (index == -1) {
        this.stores = [...this.stores, subStore];
      }
    },
  },
});

export default useStore;
