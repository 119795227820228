import Axios, { baseURL } from "@/axios";
import axios from "axios";


export const indexRequest = async () => {
  return await Axios.get("/admin/stores");
};

export const deleteStoreRequest = async(id)=>{
    let response;
    await axios.get(`${baseURL}/sanctum/csrf-cookie`).then(()=>{
        response = Axios.delete(`admin/stores/${id}`);
    });

    return response;
}

export const createStore = async(data)=>{
    let response;
    await axios.get(`${baseURL}/sanctum/csrf-cookie`).then(()=>{
        response = Axios.post(`admin/stores/create`,data);
    });

    return response;
}

export const updateStore = async(id,data)=>{
    let response;
    await axios.get(`${baseURL}/sanctum/csrf-cookie`).then(()=>{
        response = Axios.patch(`admin/stores/${id}`,data);
    });

    return response;
}
