<script setup></script>

<template>
  <div
    class="bg-palette1-green-4 flex flex-nowrap flex-row justify-center md:justify-between items-center fixed bottom-0 min-w-full z-[1001]"
  >
    <routerLink :to="{name : 'home'}" class="bi bi-house-fill text-palette1-gray-1 text-3xl p-4 mx-4"></routerLink>
    <routerLink :to="{name : 'roster'}" class="bi bi-ui-checks text-palette1-gray-1 text-3xl p-4 mx-4"></routerLink>
    <routerLink :to="{name : 'categories'}" class="bi bi-collection-fill text-palette1-gray-1 text-3xl p-4 mx-4"></routerLink>
    <i class="bi bi-person-fill text-white text-3xl p-4 mx-4"></i>
  </div>
</template>
