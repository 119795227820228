<script setup>
import Axios, { baseURL } from '@/axios';
import useUser from '@/store/user';
import swal from '@/swal';
import { computed, defineProps, onMounted, ref, watch } from 'vue';
import ProductCartEditModalComponent from './ProductCartEditModalComponent.vue'
import useHomeProducts from '@/store/homeProducts';

const Props = defineProps({
    product: {
        required: true,
        type: Object
    },
    setImageModalUrl: {
        required: true,
        type: Function
    },
    search: {
        type: String
    }
});

const homeProductsStore = useHomeProducts();
const quantity = ref(
    Props.product.inRoster == null ?
        0
        : (Props.product.inRoster && Props.product.inRoster.status == 0) ?
            Props.product.inRoster.quantity
            : 0
);



const loading = ref(false);
let saveTimeout = null;
const userStore = useUser();
const shownEditSlide = ref(false);
const actionShown = ref(false);
const productName = computed(() => {
    if (Props.search.length) {
        let searchItems = Props.search.split(' ');
        let highLightName = Props.product.name;
        searchItems.forEach((searchItem) => {
            highLightName = highLightName.replaceAll(searchItem, `<span class="bg-green-600 text-white inline-block">${searchItem}</span>`)
        })
        return highLightName;
    } else {
        return Props.product.name
    }
});


const decrementQuantityAsCarton = () => {
    if (quantity.value > 0) {
        if (quantity.value < Props.product.carton_contains) {
            quantity.value = 0;
        } else {
            quantity.value -= Props.product.carton_contains;
        }

    }
}

const incrementQuantityAsCarton = () => {
    if (quantity.value < Props.product.quantity) {
        if (quantity.value + Props.product.carton_contains > Props.product.quantity) {
            quantity.value = Props.product.quantity;
        } else {
            quantity.value += Props.product.carton_contains;
        }
    }
}

const decrementQuantity = () => {
    if (quantity.value > 0) {
        quantity.value -= 1;

    }
}

const incrementQuantity = () => {
    if (quantity.value < Props.product.quantity) {
        quantity.value += 1;
    }
}

const saveToRoster = async () => {
    if (Props.product.inRoster || (!Props.product.inRoster && quantity.value > 0)) {
        try {
            loading.value = true;
            Axios.get(`${baseURL}/sanctum/csrf-cookie`).then(async () => {
                let response = await Axios.post('/roster', { product_id: Props.product.id, quantity: quantity.value });
                loading.value = false;
                homeProductsStore.editItem(response.data.data.item);
            });

        } catch (error) {
            loading.value = false;
            console.log(error)
            swal('error', 'خطا در برقراری ارتباط با سرور.')
        }
    }

}


// watchers
watch(() => Props.product.quantity, () => {
    if (quantity.value > Props.product.quantity) {
        quantity.value = Props.product.quantity;
    }
});


onMounted(() => {
    watch(quantity, () => {
        if (saveTimeout) {
            clearTimeout(saveTimeout);
        }
        saveTimeout = setTimeout(() => {
            saveToRoster();
        }, 1200)

    });
})

</script>

<template>
    <div class="bg-white p-2 rounded-md flex-flex-col relative" @click="() => {
        if (Props.product.quantity) {
            actionShown = true;
        }
    }
        " v-click-outside="() => {
        actionShown = false
    }">
        <div class="flex flex-row items-center mb-2">
            <img @click="Props.setImageModalUrl(`${Props.product.image}& width=400 & height=400`)"
                :src="`${Props.product.image}&width=50&height=50`" :alt="Props.product.name" width="50" height="50" />
            <div class="flex flex-col mx-2 space-y-2">
                <span class="text-gray-800 text-sm font-bold" v-html="productName"></span>
                <!-- <span class="text-gray-400 font-bold text-xs">دسته بندی:<span class="text-red-500">{{
                        Props.product.category ? Props.product.category.name : 'پاک شده' }}</span></span> -->
                <span v-if="Props.product.quantity" class="text-gray-400 font-bold text-xs">موجودی‌: تعداد <span
                        class="text-red-900 mx-1">{{
                            Props.product.quantity }} </span>عدد در قالب کارتن های<span class="text-red-900 mx-1">{{
        Props.product.carton_contains }} </span> عددی</span>
                <span v-else class="text-red-600 font-bold text-xs">عدم موجودی</span>
            </div>
        </div>
        <Transition enter-active-class="transition-all duration-300 origin-top"
            leave-active-class="transition-all duration-300 origin-top" enter-from-class="scale-y-0"
            enter-to-class="scale-y-100" leave-from-class="scale-y-100" leave-to-class="scale-y-0">
            <div v-show="actionShown" class="flex flex-row items-center">
                <div class="basis-8/12 flex flex-col justify-center px-2">
                    <div class="flex flex-row items-center">
                        <button :disabled="loading" @click="incrementQuantity" class="relative p-2 bg-palette1-orange-1 disabled:bg-yellow-700 rounded-md me-2">
                            <i class="bi bi-caret-up-fill text-2xl"></i>
                        </button>
                        <button :disabled="loading" @click="incrementQuantityAsCarton"
                            class="relative bg-palette1-orange-1 disabled:bg-yellow-700 rounded-md me-2 px-5 py-6">
                            <!-- <i class="bi bi-dropbox text-2xl"></i> -->
                            <i class="bi bi-caret-up-fill text-lg absolute top-[50%] right-[50%] translate-x-[50%] translate-y-[-40%]"></i>
                            <i class="bi bi-caret-up-fill text-lg absolute top-[50%] right-[50%] translate-x-[50%] translate-y-[-60%]"></i>
                        </button>

                        <div class="p-2 bg-sky-200 rounded-md">{{ quantity }}</div>


                        <button :disabled="loading" @click="decrementQuantityAsCarton"
                            class="relative p-2 bg-palette1-orange-1 disabled:bg-yellow-700 rounded-md ms-2 px-5 py-6">
                            <!-- <i class="bi bi-dropbox text-2xl"></i> -->
                            <i class="bi bi-caret-down-fill text-lg absolute bottom-[50%] right-[50%] translate-x-[50%] translate-y-[50%]"></i>
                            <i class="bi bi-caret-down-fill text-lg absolute bottom-[50%] right-[50%] translate-x-[50%] translate-y-[70%]"></i>
                        </button>

                        <button :disabled="loading" @click="decrementQuantity" class="relative p-2 bg-palette1-orange-1 disabled:bg-yellow-700 rounded-md ms-2">
                            <i class="bi bi-caret-down-fill text-2xl"></i>
                        </button>
                    </div>
                    <div v-if="Props.product.inRoster">
                        <span v-if="Props.product.inRoster.status == 0" class="font-bold text-xs mt-2 text-red-600">تعداد {{
                            Props.product.inRoster.quantity }} عدد توسط {{ Props.product.inRoster.user &&
        Props.product.inRoster.user.id ==
        userStore.user.id ? 'شما' : Props.product.inRoster.user ? Props.product.inRoster.user.name :
        'کاربر پاک شده' }} در لیست ثبت
                            شده.</span>
                        <span v-else class="font-bold text-xs mt-2 text-green-600">مقدار {{
                            Props.product.inRoster.quantity
                        }} عدد بارگیری شد.</span>
                    </div>


                </div>
                <div class="basis-4/12 flex justify-end">
                    <div v-if="loading"
                        class="inline-block animate-spin p-2 bg-transparent  w-6 h-6 !border-4 border-transparent  !border-b-palette1-blue-1 rounded-full top-1/2 right-1/2 ms-2">
                    </div>
                </div>

            </div>
        </Transition>

        <button v-show="userStore.user && (userStore.role == 'Super Admin' || userStore.role == 'Store Keeper')" @click="(e) => {
            shownEditSlide = true
        }
            " v-click-outside="() => shownEditSlide = false"
            class="absolute py-1 px-2 bg-gray-300 bg-opacity-80 rounded-md top-0 left-0 m-2 text-white">
            <i class="bi bi-pencil-fill text-green-600"></i>

            <Transition enter-active-class="transition-all duration-300 origin-right"
                leave-active-class="transition-all duration-300 origin-right" enter-from-class="scale-x-0"
                enter-to-class="scale-x-100" leave-from-class="scale-x-100" leave-to-class="scale-x-0">
                <ProductCartEditModalComponent v-if="shownEditSlide" :product="Props.product"
                    :close="() => shownEditSlide = false" />

            </Transition>
        </button>


        <!-- status info -->
        <div v-if="Props.product.inRoster && Props.product.inRoster.status == 0"
            class="absolute h-6 w-6 overflow-hidden top-0 left-0 rotate-180">
            <span class="w-12 h-12 block bg-yellow-500  rotate-45 translate-x-[2rem]"></span>
        </div>
        <div v-else-if="Props.product.inRoster && Props.product.inRoster.status == 1"
            class="absolute h-6 w-6 overflow-hidden top-0 left-0 rotate-180">
            <span class="w-12 h-12 block bg-green-500  rotate-45 translate-x-[2rem]"></span>
        </div>

    </div>
</template>