import axios from "axios";
import Axios, { baseURL } from "@/axios";

export const createCategory = async (formData) => {
  let response;
  await axios.get(`${baseURL}/sanctum/csrf-cookie`).then(() => {
    response = Axios.post("/admin/categories/create", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  });

  return response;
};

export const indexRequest = async (sort = "asc", search='') => {
  return await Axios.get(`/admin/categories?sort=${sort}&search=${search}`);
};

export const deleteCategory = async (id) => {
  let response;
  await axios.get(`${baseURL}/sanctum/csrf-cookie`).then(() => {
    response = Axios.delete(`/admin/categories/${id}`);
  });

  return response;
};

export const updateCategory = async (id, formData) => {
  let response;
  await axios.get(`${baseURL}/sanctum/csrf-cookie`).then(() => {
    response = Axios.post(
      `/admin/categories/${id}`,
      { ...formData, _method: "PATCH" },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  });

  return response;
};

