<script setup>
import { ref } from "vue";
import swal from "@/swal";
import { useRouter, useRoute } from "vue-router";
import { updateStore } from "@/requests/storeRequest";
import useStore from "@/store/stores";

const router = useRouter();
const route = useRoute();
const storeStore = useStore()
const storeId = route.params.id;

const storeData = ref({
  name: null,
});

const storeValidation = ref({
  name: null,
});

const findStore = () => {
  let find = null;

  if (storeStore.stores) {
    find = storeStore.stores.find((store) => store.id == storeId);
  }

  if (!find) {
    swal("error", `انبار مد نظر یافت نشد.`);
    router.replace({ name: "admin.stores.index" });
  } else {
    storeData.value.name = find.name
  }
}

const submitUpdateStore = async () => {
  try {
    let response = await updateStore(storeId, storeData.value);

    if (response.data.status == "success") {
      swal("success", `انبار ${storeData.value.name} ویرایش شد.`);
      storeStore.editStore(response.data.data.store);
      router.replace({ name: "admin.stores.index" });
    }
  } catch (error) {
    if (error.response && error.response.status == 422) {
      storeValidation.value = error.response.data.errors;
    } else {
      console.log(error.response);
    }
  }
};

findStore();

</script>

<template>
  <div class="min-w-full flex flex-col justify-start items-start bg-transparent rounded-lg p-4 mb-5">
    <div class="w-full flex flex-row justify-between items-center mb-5">
      <span class="font-bold text-gray-600">ویرایش انبار</span>
      <RouterLink :to="{ name: 'admin.stores.index' }"><i
          class="bi bi-arrow-left bg-gray-500 px-2 py-1 text-white text-xl text-center rounded-full"></i></RouterLink>
    </div>
    <div class="mb-4 w-full">
      <div class="form-control bg-white">
        <input v-model="storeData.name" id="name" class="input-control"
          :class="{ 'outline-red-500': storeValidation.name }" type="text" placeholder=" " />
        <label for="name" class="label-control">نام انبار</label>
      </div>
      <span v-if="storeValidation.name" class="invalid">{{
        storeValidation.name[0]
      }}</span>
    </div>

    <div class="flex flex-row w-full justify-end">
      <button @click="submitUpdateStore" class="px-4 py-2 rounded-md text-black min-w-[150px]" :class="[loading ? 'bg-white' : 'bg-green-500']" :disabled="loading">
        ویرایش انبار
      </button>
    </div>
  </div>
</template>
