<script setup>
import HeaderComponent from "@/components/header/HeaderComponent.vue";
import BottomLinks from "./components/BottomLinks.vue";
import Axios from '@/axios';
import { useRouter } from "vue-router";
import useUser from '@/store/user'
import useStore from "./store/stores";
import useCategories from "./store/categories";
import useHomeProducts from "./store/homeProducts";
import useProducts from "./store/products";
import useRoster from "./store/roster";
import echo from "./echo";
import { ref, watch } from "vue";



const router = useRouter();
const userStore = useUser();
const storeStore = useStore();
const categoriesStore = useCategories();
const homeProductsStore = useHomeProducts();
const rosterStore = useRoster();
const productsStore = useProducts();
const scrollTopShown = ref(false)

Axios.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  (error) => {
    if (error.response && error.response.status == 500) {
      router.push({ name: '500' });
    } else if (error.response && error.response.status == 404) {
      router.push({ name: '404' });
    }
    return Promise.reject(error);
  }
);


const publicChannelListen = () => {
  echo.channel('categories').stopListening('.add').stopListening('.edit').stopListening('.delete')
  echo.channel('stores').stopListening('.add').stopListening('.edit').stopListening('.delete')
  echo.channel('products').stopListening('.add').stopListening('.edit').stopListening('.delete')

  echo.channel('categories').listen('.add', (e) => {
    categoriesStore.addCategory(e.category);
  });

  echo.channel('categories').listen('.edit', (e) => {
    categoriesStore.editCategory(e.category);
  });

  echo.channel('categories').listen('.delete', (e) => {
    categoriesStore.removeCategory(e.id);
  });

  echo.channel('stores').listen('.add', (e) => {
    storeStore.addStore(e.store);
  });

  echo.channel('stores').listen('.edit', (e) => {
    storeStore.editStore(e.store);
  });

  echo.channel('stores').listen('.delete', (e) => {
    storeStore.removeStore(e.id);
  });

  echo.channel('products').listen('.add', (e) => {
    homeProductsStore.addItem(e.product);
    productsStore.addItem(e.product);
  });

  echo.channel('products').listen('.edit', (e) => {
    rosterStore.editItem(e.product);
    homeProductsStore.editItem(e.product);
    productsStore.editItem(e.product);
  })

  echo.channel('products').listen('.delete', (e) => {
    rosterStore.removeItem(e.id);
    homeProductsStore.removeItem(e.id);
    productsStore.removeItem(e.id);
  });
}

const scrollTop = () => {
  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
}

window.addEventListener('scroll', () => {
  if (window.scrollY > window.screenY + 500) {
    scrollTopShown.value = true;
  } else {
    scrollTopShown.value = false;
  }
})

userStore.fetchUser();
storeStore.fetchStores();
categoriesStore.fetchCategories();
homeProductsStore.fetchData();

if (userStore.user) {
  publicChannelListen();
  if (userStore.role != 'seller') {
    productsStore.fetchData();
  }
}

watch(() => userStore.user, () => {
  if (userStore.user) {
    publicChannelListen();
    if (userStore.role != 'seller') {
      productsStore.fetchData();
    }
  }
});
</script>

<template>
  <HeaderComponent />
  <div class="pb-12 pt-6 container px-4 min-h-[95vh]">
    <RouterView />
  </div>
  <BottomLinks />
  <Transition enter-active-class="transition-all duration-500 origin-left"
    leave-active-class="transition-all duration-500 origin-left" enter-from-class="opacity-0" enter-to-class="opacity-100"
    leave-from-class="opacity-100" leave-to-class="opacity-0">
    <i v-if="scrollTopShown" @click="scrollTop"
      class="bi bi-arrow-up-circle-fill rounded-full text-palette1-purple-1 text-5xl bg-white fixed bottom-20 left-4 z-[1010]"></i>
  </Transition>
</template>

<style></style>
