<script setup>
import ProductCartComponent from "@/components/roster/ProductCartComponent"
import { ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import useCategories from '@/store/categories';
import useRoster from '@/store/roster';
import useStore from "@/store/stores";


const rosterStore = useRoster();
const categoriesStore = useCategories();
const storesStore = useStore();
const { filterItems, categories: categoriesForFilter, stores: storesForFilter } = storeToRefs(rosterStore);
const filtersSidebarShown = ref(false);



// image modal
const imageModal = ref(false);
const modalUrl = ref(null);


const showImageModal = (url) => {
    imageModal.value = true,
        modalUrl.value = url;
}

const closeModal = () => {
    imageModal.value = false,
        modalUrl.value = null;
}

const storesCheckBoxClick = (id) => {
    if (storesForFilter.value.includes(id)) {
        storesForFilter.value = storesForFilter.value.filter(row => row != id);
    } else {
        storesForFilter.value = [...storesForFilter.value, id]
    }
}

const categoriesCheckBoxClick = (id) => {
    if (categoriesForFilter.value.includes(id)) {
        categoriesForFilter.value = categoriesForFilter.value.filter(row => row != id);
    } else {
        categoriesForFilter.value = [...categoriesForFilter.value, id]
    }
}

const shownValueChanged = (e)=>{
    rosterStore.setShownValue(e.target.value);
}


// _______________________

watch(categoriesForFilter, () => {
    rosterStore.setCategoriesValue(categoriesForFilter.value);
});

watch(storesForFilter, () => {
    rosterStore.setStoresValue(storesForFilter.value);
});


rosterStore.fetchData();

</script>

<template>
    <div v-click-outside="() => { filtersSidebarShown = false }">
        <button @click="() => { filtersSidebarShown = true }"
            class="fixed top-[25vh] left-0 p-2 bg-palette1-green-1 rounded-r-md z-[1001]">
            <i class="bi bi-funnel-fill text-white"></i>
        </button>
        <Transition enter-active-class="transition-all duration-300 origin-left"
            leave-active-class="transition-all duration-300 origin-left" enter-from-class="scale-x-0"
            enter-to-class="scale-x-100" leave-from-class="scale-x-100" leave-to-class="scale-x-0">
            <div v-show="filtersSidebarShown"
                class="fixed p-2 bg-palette1-green-1 text-white min-w-[200px] max-w-[80vw] h-[50vh] top-0 translate-y-[50%] rounded-r-md border-2 border-gray-600 left-0 z-[1010] overflow-y-auto overflow-x-auto">
                <div class="flex flex-row justify-end items-center">
                    <button @click="() => { filtersSidebarShown = false }">
                        <i class="bi bi-arrow-left text-2xl"></i>
                    </button>
                </div>
                <span>انبار:</span>
                <div class="flex flex-col w-full">
                    <div @click="storesCheckBoxClick(store.id)" class="form-check" v-for="store in storesStore.stores"
                        :key="store.id">
                        <input v-model="storesForFilter" :value="store.id" type="checkbox" :id="`store-${store.id}`">
                        <span class="custom-check"> </span>
                        <label :for="`store-${store.id}`">{{ store.name }}</label>
                    </div>
                </div>
                <span>دسته بندی :</span>
                <div class="flex flex-col w-full">
                    <div @click="categoriesCheckBoxClick(category.id)" class="form-check"
                        v-for="category in categoriesStore.categories" :key="category.id">
                        <input v-model="categoriesForFilter" :value="category.id" type="checkbox"
                            :id="`category-${category.id}`">
                        <span class="custom-check"> </span>
                        <label :for="`category-${category.id}`">{{ category.name }}</label>
                    </div>
                </div>

            </div>
        </Transition>
    </div>
    <div class="flex flex-row flex-wrap justify-between items-center">
        <div>
            <span class="font-bold">تاریخ :</span>
            <span class="text-sm text-gray-600">{{ rosterStore.date }}</span>
        </div>
        <div>
            <span class="text-sm font-bold ">نمایش :</span>
            <select @change="shownValueChanged" class="p-1 rounded-lg text-sm">
                <option value="all" :selected="rosterStore.shown == 'all'">نمایش همه</option>
                <option value="pending" :selected="rosterStore.shown == 'pending'">در انتظار بارگیری</option>
            </select>
        </div>
    </div>

    <div v-if="rosterStore.items.length < 1" class="flex flex-row justify-center my-4 min-h-[60vh]">
        <span v-if="rosterStore.loading" class="font-bold text-sm text-gray-800">در حال دریافت اطلاعات ...</span>
        <span v-else class="font-bold text-sm text-gray-800">محصولی ثبت نشده.</span>
    </div>
    <div v-else class="w-full flex flex-col md:flex-row flex-wrap md:items-center">
        <div v-if="rosterStore.filterItems.length < 1" class="flex flex-row justify-center my-4 min-h-[60vh]">
            <span class="font-bold text-sm text-gray-800">موردی یافت نشد.</span>
        </div>

        <ProductCartComponent v-for="product in filterItems" :key="product.id" :product="product"
            :setImageModalUrl="(url) => showImageModal(url)" />
    </div>
    <div v-if="imageModal" class="fixed w-[100vw] h-[100vh] bg-black bg-opacity-50 top-0 right-0 z-[1010]">
        <button @click="closeModal"
            class="border-2 border-white rounded-md absolute top-4 right-4 p-2 bg-gray-800 cursor-pointer">
            <i class="bi bi-x-lg text-3xl text-white"></i>
        </button>
        <img :src="modalUrl"
            class="max-w-[400px] max-h-[400px] right-1/2 top-1/2 absolute translate-x-[50%] translate-y-[-50%]" alt="">
    </div>
</template>