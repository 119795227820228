<script setup>
import { onMounted, ref, watch } from "vue";
import TableRowComponent from "@/components/admin/products/TableRowComponent"
import SelectComponent from "@/components/inputs/SelectComponent.vue";
import useStore from "@/store/stores";
import useCategories from "@/store/categories";
import useProducts from "@/store/products"
import { storeToRefs } from "pinia";
import PaginateComponent from '@/components/admin/products/PaginateComponent.vue'

const storeStore = useStore();
const categoriesStore = useCategories();
const productsStore = useProducts();
const { categories } = storeToRefs(categoriesStore);
const { stores } = storeToRefs(storeStore);
const { current_page_products: products, last_page, filters, loading } = storeToRefs(productsStore);
const searchDispatch = ref(true);
const disableRowAction = ref(false)


const filtersBoxShown = ref(false);
onMounted(() => {
  if (filters.value.category || filters.value.store) {
    filtersBoxShown.value = true;
  }
});

let searchTimeOut = null;

const imageModal = ref(false);
const modalUrl = ref(null);


const showImageModal = (url) => {
  document.body.style.overflow = 'hidden'
  imageModal.value = true,
    modalUrl.value = url;
}

const closeModal = () => {
  document.body.style.overflow = 'unset'
  imageModal.value = false,
    modalUrl.value = null;
}

watch(() => filters.value.search, () => {
  searchDispatch.value = false;
  if (searchTimeOut) {
    clearTimeout(searchTimeOut)
  }
  searchTimeOut = setTimeout(() => {
    productsStore.filterItems();
    searchDispatch.value = true;
  }, 700);

})

const productDelete = (id) => {
  products.value = products.value.filter((product) => product.id != id);
};

const setZeroEntity = (id) => {
  let productIndex = products.value.findIndex((product) => product.id == id);
  products.value[productIndex].quantity = 0;
}

watch(() => filters.value.sort, () => productsStore.filterItems(productsStore.current_page));
watch(() => filters.value.sortDirection, () => productsStore.filterItems(productsStore.current_page));
watch(() => filters.value.category, () => productsStore.filterItems());
watch(() => filters.value.store, () => productsStore.filterItems());

</script>

<template>
  <div class="flex flex-row justify-between items-center mx-4">
    <span class="font-bold text-gray-500 text-xl">محصولات</span>
    <RouterLink :to="{ name: 'admin.products.create' }"
      class="flex flex-row justify-between items-center bg-white text-gray-500 p-2 rounded-md">
      <span>ایجاد محصول جدید</span>
      <i class="bi bi-plus-square-fill ms-2 mt-1"></i>
    </RouterLink>
  </div>
  <section class="container px-4 mx-auto">
    <div class="mt-6 md:flex md:items-center md:justify-between z-[999]">
      <div class="relative flex items-center mt-4 md:mt-0">
        <span class="absolute">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
            class="w-5 h-5 mx-3 text-gray-400 dark:text-gray-600">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
          </svg>
        </span>
        <span v-if="!searchDispatch" class="absolute top-0 left-0 flex h-3 w-3">
          <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
          <span class="relative inline-flex rounded-full h-3 w-3 bg-sky-500"></span>
        </span>
        <input v-model.trim="filters.search" type="text" placeholder="جستجو"
          class="block w-full py-1.5 pr-5 text-gray-700 bg-white border border-gray-200 rounded-lg md:w-80 placeholder-gray-400/70 pl-11 rtl:pr-11 rtl:pl-5 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 dark:focus:border-blue-300 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40" />
      </div>
    </div>

    <div class="mt-4 w-full">
      <div class="text-gray-600">
        <button @click="filtersBoxShown = !filtersBoxShown"
          class="flex flex-row p-2 items-center bg-palette1-green-2 rounded-md">
          <i class="bi bi-funnel-fill text-xl mx-2  text-gray-200"></i>
          <span class="font-bold text-white">فیلتر ها</span>
          <i class="bi bi-arrow-down text-xl mx-2 text-white transition-all duration-300"
            :class="{ 'rotate-180': filtersBoxShown }"></i>
        </button>
      </div>
      <Transition enter-active-class="transition-all duration-300 origin-top"
        leave-active-class="transition-all duration-300 origin-top" enter-from-class="scale-y-0"
        enter-to-class="scale-y-100" leave-from-class="scale-y-100" leave-to-class="scale-y-0">
        <div v-show="filtersBoxShown" class="w-full md:flex flex-row">
          <div class="px-2">
            <SelectComponent :labelDisable="false" :options="categories" :default-select="filters.category"
              :onChange="(select) => (select == '' ? filters.category = null : filters.category = select)"
              label='دسته بندی محصول را انتخاب کنید (همه دسته بندی ها)' />
          </div>
          <div class="px-2">
            <SelectComponent :labelDisable="false" :options="stores" :default-select="filters.store"
              :onChange="(select) => (select == '' ? filters.store = null : filters.store = select)"
              label='انبار قرارگیری محصول را انتخاب کنید (همه انبار ها)' />
          </div>
        </div>
      </Transition>


    </div>

    <div class="flex flex-col mt-6 mb-12">
      <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div class="overflow-hidden border border-gray-200 dark:border-gray-700 md:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
              <thead class="bg-gray-50 dark:bg-gray-800">
                <tr>
                  <th scope="col"
                    class="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <button @click="() => {
                      filters.sort = 'name',
                        filters.sortDirection == 'asc' ? filters.sortDirection = 'desc' : filters.sortDirection = 'asc'
                    }" class="flex items-center gap-x-3 focus:outline-none">
                      <span>نام</span>

                      <div v-if="filters.sort == 'name'">
                        <i v-if="filters.sortDirection == 'asc'" class="bi bi-sort-alpha-down text-xl ms-2"></i>
                        <i v-else class="bi bi-sort-alpha-down-alt text-xl ms-2"></i>
                      </div>
                    </button>
                  </th>

                  <th scope="col"
                    class="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    تصویر
                  </th>

                  <th scope="col"
                    class="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    بارکد
                  </th>

                  <th @click="() => {
                    filters.sort = 'store',
                      filters.sortDirection == 'asc' ? filters.sortDirection = 'desc' : filters.sortDirection = 'asc'
                  }" scope="col"
                    class="items-center cursor-pointer px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <div class="flex">
                      <span>انبار</span>
                      <div v-if="filters.sort == 'store'">
                        <i v-if="filters.sortDirection == 'asc'" class="bi bi-sort-alpha-down text-xl ms-2"></i>
                        <i v-else class="bi bi-sort-alpha-down-alt text-xl ms-2"></i>
                      </div>
                    </div>
                  </th>

                  <th @click="() => {
                    filters.sort = 'category',
                      filters.sortDirection == 'asc' ? filters.sortDirection = 'desc' : filters.sortDirection = 'asc'
                  }" scope="col"
                    class="first-letter:items-center cursor-pointer px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <div class="flex">
                      <span>دسته بندی</span>
                      <div v-if="filters.sort == 'category'">
                        <i v-if="filters.sortDirection == 'asc'" class="bi bi-sort-alpha-down text-xl ms-2"></i>
                        <i v-else class="bi bi-sort-alpha-down-alt text-xl ms-2"></i>
                      </div>
                    </div>

                  </th>

                  <th scope="col"
                    class="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    موجودی
                  </th>

                  <th scope="col"
                    class="relative px-4 py-3.5 text-sm text-end font-normal text-gray-500 dark:text-gray-400">
                    <span class="">عملیات</span>
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
                <div v-if="loading"
                  class=" animate-spin absolute w-8 h-8 !border-4  !border-b-blue-500 rounded-full top-1/2 right-1/2">
                </div>
                <TableRowComponent v-else v-for="product in products" :key="product.id" :product="product"
                  :search="filters.search" :onDelete="(id) => productDelete(id)"
                  :onSetZeroEntity="(id) => setZeroEntity(id)" :disableAction="disableRowAction"
                  :setDisableAction="(bool) => disableRowAction = bool" :setImageModalUrl="(url) => showImageModal(url)"
                  :filters="filters" />
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div v-if="last_page > 1" class="w-full mb-12">
      <PaginateComponent />
    </div>
  </section>
  <div v-if="imageModal" class="fixed w-[100vw] h-[100vh] bg-black bg-opacity-50 top-0 right-0 z-[1010]">
    <button @click="closeModal"
      class="border-2 border-white rounded-md absolute top-4 right-4 p-2 bg-gray-800 cursor-pointer">
      <i class="bi bi-x-lg text-3xl text-white"></i>
    </button>
    <img :src="modalUrl"
      class="max-w-[400px] max-h-[400px] right-1/2 top-1/2 absolute translate-x-[50%] translate-y-[-50%]" alt="">
  </div></template>
