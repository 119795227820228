<script setup>
import useProducts from "@/store/products.js";
import { storeToRefs } from "pinia";
import { ref, watch } from "vue";

const productsStore = useProducts();


let pageLinksArray = [];
const availablePages = ref([]);
const prevLink = ref(null);
const nextLink = ref(null);
const { current_page, last_page } = storeToRefs(productsStore);


const fillAvailablePages = () => {
    pageLinksArray = [];
    for (let i = 0; i < last_page.value; i++) {
        pageLinksArray[i] = i + 1;
    }

    if (pageLinksArray.length <= 7) {
        availablePages.value = [...pageLinksArray];
    } else if (current_page.value <= 4) {
        availablePages.value = pageLinksArray.slice(
            0,
            current_page.value + 3 + (4 - current_page.value)
        );
    } else {
        availablePages.value = pageLinksArray.slice(
            current_page.value - 4,
            current_page.value + 3
        );
    }
};

const fillPrevAndNextLink = () => {
    if (current_page.value > 1) {
        prevLink.value = current_page.value - 1;
    } else {
        prevLink.value = null;
    }
    if (current_page.value < last_page.value) {
        nextLink.value = current_page.value + 1;
    } else {
        nextLink.value = null;
    }
};

watch([current_page, last_page], () => {
    fillAvailablePages();
    fillPrevAndNextLink();
});

fillAvailablePages();
fillPrevAndNextLink();

</script>

<template>
    <div class="flex flex-row flex-wrap justify-center w-full">
        <button @click="productsStore.filterItems(current_page - 1)"
            class="border border-1 border-green-400 px-2 py-1 rounded-lg mx-2 disabled:bg-slate-400"
            :disabled="prevLink == null">
            &laquo;
        </button>
        <button v-for="(link, index) in availablePages" :key="index"
            class="border border-1 border-green-400 px-2 py-1 rounded-lg mx-2" :class="[
                link == current_page ? 'bg-green-400' : 'bg-white-100',
            ]" :disabled="link == current_page" @click="productsStore.filterItems(link)">
            {{ link }}
        </button>
        <button @click="productsStore.filterItems(current_page + 1)"
            class="border border-1 border-green-400 px-2 py-1 rounded-lg mx-2 disabled:bg-slate-400"
            :disabled="nextLink == null">
            &raquo;
        </button>
    </div>
</template>
