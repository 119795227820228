<script setup>
import { ref } from "vue";
import swal from "@/swal";
import { useRouter } from "vue-router";
import { indexRequest } from "@/requests/roleRequest";
import SelectComponent from "@/components/inputs/SelectComponent";
import Axios, { baseURL } from "@/axios";

const router = useRouter();
const loading = ref(false);
const uploaded = ref(0);
const registerData = ref({
  name: null,
  phone: null,
  password: null,
  confirm_password: null,
  role: null,
});

const validationErrors = ref({
  name: null,
  phone: null,
  password: null,
  password_confirmation: null,
  role: null,
});

const roles = ref([]);

const fetchRoles = async () => {
  let response = await indexRequest();
  roles.value = response.data.data.roles;
};

const submitRegisterForm = () => {
  loading.value = true;
  Axios.get(`${baseURL}/sanctum/csrf-cookie`).then(() => {
    Axios.post("/register", registerData.value, {
      onUploadProgress: (e) => {
        uploaded.value = Math.floor((e.loaded * 100) / e.total);
      },
    }).then(response => {
      loading.value = false;
      uploaded.value = 0;

      if (response.data && response.data.status == "success") {
        swal("success", `کاربر ${registerData.value.name} ایجاد شد.`);
        router.replace({ name: "admin.users.index" });
      }
    }).catch(error => {
      loading.value = false;
      uploaded.value = 0;
      if (error.response && error.response.status == 422) {
        validationErrors.value = error.response.data.errors;
      } else {
        console.log(error.response);
      }
    });
  }).catch(err=>{
    console.log(err)
  });

};

fetchRoles();
</script>

<template>
  <div class="min-w-full flex flex-col justify-start items-start bg-transparent rounded-lg p-4 mb-5">
    <div class="w-full flex flex-row justify-between items-center mb-5">
      <span class="font-bold text-gray-600">ایجاد کاربر جدید</span>
      <RouterLink :to="{ name: 'admin.users.index' }"><i
          class="bi bi-arrow-left bg-gray-500 px-2 py-1 text-white text-xl text-center rounded-full"></i></RouterLink>
    </div>
    <div class="mb-4 w-full">
      <div class="form-control bg-white">
        <input v-model="registerData.name" id="name" class="input-control"
          :class="{ 'outline-red-500': validationErrors.name }" type="text" placeholder=" " />
        <label for="name" class="label-control">نام کاربری</label>
      </div>
      <span v-if="validationErrors.name" class="invalid">{{
        validationErrors.name[0]
      }}</span>
    </div>
    <div class="mb-4 w-full">
      <div class="form-control bg-white flex items-center">
        <input v-model="registerData.phone" id="phone" class="input-control rounded-l-none"
          :class="{ 'outline-red-500': validationErrors.phone }" type="text" placeholder=" " />
        <span class="p-2 bg-gray-500 text-white border-2 border-gray-500 rounded-md rounded-r-none">98+</span>
        <label for="phone" class="label-control">شماره همراه</label>
      </div>
      <span v-if="validationErrors.phone" class="invalid">{{
        validationErrors.phone[0]
      }}</span>
    </div>

    <div class="mb-4 w-full">
      <div class="form-control bg-white">
        <input v-model="registerData.password" id="password" class="input-control"
          :class="{ 'outline-red-500': validationErrors.password }" type="password" placeholder=" " />
        <label for="password" class="label-control">رمز عبور</label>
      </div>
      <span v-if="validationErrors.password" class="invalid">{{
        validationErrors.password[0]
      }}</span>
    </div>
    <div class="mb-4 w-full">
      <div class="form-control bg-white">
        <input v-model="registerData.password_confirmation" id="password_confirmation" class="input-control"
          :class="{ 'outline-red-500': validationErrors.password_confirmation }" type="password" placeholder=" " />
        <label for="password_confirmation" class="label-control">تکرار رمز عبور</label>
      </div>
      <span v-if="validationErrors.password_confirmation" class="invalid">{{
        validationErrors.password_confirmation[0]
      }}</span>
    </div>
    <SelectComponent :options="roles" :onChange="(select) => (registerData.role = select)" :error="validationErrors.role"
      label="نوع کاربری رو انتخاب کنید" />

    <div class="flex flex-row w-full justify-end">
      <button @click="submitRegisterForm" class="px-4 py-2 rounded-md text-black relative min-w-[150px]"
        :class="[loading ? 'bg-white' : 'bg-green-500']" :disabled="loading">
        {{ uploaded != 0 ? uploaded + '%' : 'ایجاد کاربر' }}
        <span v-if="uploaded != 0" class="bg-green-500 bg-opacity-80 h-full absolute top-0 left-0 rounded-md"
          :style="{ width: uploaded + '%' }"></span>
      </button>
    </div>
  </div>
</template>
