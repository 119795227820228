<script setup>
import useStore from '@/store/stores';
import {storeToRefs} from 'pinia';
import TableRowComponent from '@/components/admin/stores/TableRowComponent.vue';

const storeStore = useStore();
const {stores} = storeToRefs(storeStore);

</script>

<template>
  <div class="flex flex-row justify-between items-center mx-4">
    <span class="font-bold text-gray-500 text-xl">انبار ها</span>
    <RouterLink :to="{ name: 'admin.stores.create' }"
      class="flex flex-row justify-between items-center bg-white text-gray-500 p-2 rounded-md">
      <span>ایجاد انبار جدید</span>
      <i class="bi bi-plus-square-fill ms-2 mt-1"></i>
    </RouterLink>
  </div>
  <div class="flex flex-row flex-wrap justify-evenly">
    <section class="container px-4 mx-auto">
      <div class="flex flex-col mt-6 mb-12">
        <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div class="overflow-hidden border border-gray-200 dark:border-gray-700 md:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                <thead class="bg-gray-50 dark:bg-gray-800">
                  <tr>
                    <th scope="col"
                      class="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                      <button class="flex items-center gap-x-3 focus:outline-none">
                        <span>نام</span>
                      </button>
                    </th>

                    <th scope="col"
                      class="relative px-8 py-3.5 text-sm text-end font-normal text-gray-500 dark:text-gray-400">
                      <span class="">عملیات</span>
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
                  <TableRowComponent v-for="store in stores" :key="store.id" :store="store" />
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
