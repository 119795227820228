<script setup>
import { ref, defineProps } from "vue";
import { deleteCategory } from "@/requests/categories";
import swal from "@/swal";
import useCategories from "@/store/categories";
import useUser from "@/store/user";

const destroyLoading = ref(false);
const userStore = useUser();
const Props = defineProps({
  category: {
    type: Object,
    required: true,
  },
  onDelete: {
    type: Function,
    required: true,
  },
  searchDispatch: {
    type: Boolean,
    required: true,
  },
  search: {
    required: true,
  },
});

const destroyCategory = async () => {
  try {
    destroyLoading.value = true;
    await deleteCategory(Props.category.id);
    Props.onDelete(Props.category.id);
    useCategories().removeCategory(Props.category.id);
    swal("success", "دسته بندی با موفقیت حذف شد.");
  } catch (error) {
    destroyLoading.value = false;
    console.log(error);
  }
};
</script>

<template>
  <tr class="even:bg-slate-50">
    <td>
      <div class="w-full flex flex-row items-center px-2">
        <img class="rounded-full border-2" :src="`${Props.category.image}&width=100&height=100`" width="50"
          :alt="category.name" />
        <div v-if="Props.searchDispatch && Props.search" class="font-medium text-gray-800 dark:text-white ms-2">
          <span>
            {{
              Props.category.name.slice(
                0,
                Props.category.name.search(Props.search)
              )
            }}
          </span>
          <span class="bg-green-500">
            {{
              Props.category.name.slice(
                Props.category.name.search(Props.search),
                Props.category.name.search(Props.search) + Props.search.length
              )
            }}
          </span>
          <span>
            {{
              Props.category.name.slice(
                Props.category.name.search(Props.search) + Props.search.length
              )
            }}
          </span>
        </div>
        <span v-else class="font-medium text-gray-800 dark:text-white ms-2">
          {{ Props.category.name }}
        </span>
      </div>
    </td>
    <td>
      <div class="flex flex-row justify-end items-center px-4 space-x-2">
        <div class="py-2">
          <i v-if="destroyLoading"
            class="bi bi-gear-fill text-xl text-gray-700 py-1 px-2 m-2 bg-gray-300 rounded-md cursor-pointer"></i>
          <RouterLink v-else :to="{ name: 'admin.categories.edit', params: { id: category.id } }">
            <i class="bi bi-gear-fill text-xl text-gray-700 py-1 px-2 m-2 bg-gray-300 rounded-md cursor-pointer"></i>
          </RouterLink>
        </div>
        <div class="py-2" v-if="userStore.role == 'Super Admin'">
          <button v-if="!destroyLoading" @click="destroyCategory"
            class="py-1 px-2 m-2 bg-gray-300 disabled:bg-gray-500 rounded-md cursor-pointer">
            <i class="bi bi-trash-fill text-xl text-red-500"></i></button>
          <div v-else class="p-1 m-2 bg-gray-300 rounded-md">
            <div
              class=" animate-spin p-2 bg-gray-300  w-5 h-5 !border-4  !border-b-blue-500 rounded-full top-1/2 right-1/2">
            </div>
          </div>
        </div>
      </div>
    </td>
  </tr>
</template>
