<script setup>
import { ref } from 'vue';
import swal from '@/swal';
import { useRouter } from 'vue-router';
import ImageInput from '@/components/inputs/ImageInput.vue';
import Axios,{baseURL} from '@/axios';
import useCategories from '@/store/categories';
// import {createCategory} from "@/requests/categories" 

const categoriesStore = useCategories();
const router = useRouter();
const loading = ref(false)
const uploaded = ref(0);
const categoryData = ref({
  name: null,
  image: null
});

const categoryValidation = ref({
  name: null,
  image: null
});

const submitCreateCategory = async () => {
  loading.value = true;
  Axios.get(`${baseURL}/sanctum/csrf-cookie`).then(() => {
    Axios.post("/admin/categories/create", categoryData.value, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (e) => {
        uploaded.value = Math.floor((e.loaded * 100) / e.total);
      }
    }).then(response => {
      loading.value = false;
      uploaded.value = 0;

      if (response.data.status == "success") {
        swal("success", `دسته ${categoryData.value.name} ایجاد شد.`);
        categoriesStore.addCategory(response.data.data.category);
        router.replace({ name: "admin.categories.index" });
      }
    }).catch(error => {
      loading.value = false;
      uploaded.value = 0;
      if (error.response && error.response.status == 422) {
      categoryValidation.value = error.response.data.errors;
    } else {
      console.log(error.response);
    }
    });
  }).catch(err => {
    console.log(err)
  });
}

</script>

<template>
  <div class="min-w-full flex flex-col justify-start items-start bg-transparent rounded-lg p-4 mb-5">
    <div class="w-full flex flex-row justify-between items-center mb-5">
      <span class="font-bold text-gray-600">ایجاد دسته جدید</span>
      <RouterLink :to="{ name: 'admin.categories.index' }"><i
          class="bi bi-arrow-left bg-gray-500 px-2 py-1 text-white text-xl text-center rounded-full"></i></RouterLink>
    </div>
    <div class="mb-4 w-full">
      <div class="form-control bg-white">
        <input v-model="categoryData.name" id="name" class="input-control"
          :class="{ 'outline-red-500': categoryValidation.name }" type="text" placeholder=" " />
        <label for="name" class="label-control">نام دسته بندی</label>
      </div>
      <span v-if="categoryValidation.name" class="invalid">{{
        categoryValidation.name[0]
      }}</span>
    </div>
    <div class="mb-4 w-full">
      <ImageInput :onChange="(event) => (categoryData.image = event.target.files[0])" :error="categoryValidation.image" />
    </div>

    <div class="flex flex-row w-full justify-end">
      <button @click="submitCreateCategory" class="px-4 py-2 rounded-md text-black relative min-w-[150px]"
        :class="[loading ? 'bg-gray-400' : 'bg-green-500']" :disabled="loading">
        {{ uploaded != 0 ? uploaded + '%' : 'ایجاد دسته بندی' }}
        <span v-if="uploaded != 0" class="bg-green-500 bg-opacity-80 h-full absolute top-0 left-0 rounded-md"
          :style="{ width: uploaded + '%' }"></span>
      </button>
    </div>

  </div>
</template>
