<script setup>
import Axios, { baseURL } from "@/axios";
import useHomeProducts from "@/store/homeProducts";
import useRoster from "@/store/roster";
import useUser from "@/store/user";
import swal from "@/swal";
// import swal from '@/swal';
import { computed, defineProps, ref } from "vue";

const Props = defineProps({
  product: {
    required: true,
    type: Object,
  },
  setImageModalUrl: {
    required: true,
    type: Function,
  },
});

const homeProductsStore = useHomeProducts();
const rosterStore = useRoster();
const userStore = useUser();
const actionLoading = ref(false);
const actionShown = ref(false);
const clickTimeout = ref(null);
const quantityOfCartons = computed(() =>
  Math.floor(Props.product.quantity / Props.product.carton_contains)
);
const microQuantity = computed(() =>
  Math.floor(Props.product.quantity % Props.product.carton_contains)
);

const quantityOfCartonsInList = computed(() =>
  Math.floor(Props.product.inRoster.quantity / Props.product.carton_contains)
);
const microQuantityInList = computed(() =>
  Math.floor(Props.product.inRoster.quantity % Props.product.carton_contains)
);

const handleClick = () => {
  if (!clickTimeout.value) {
    clickTimeout.value = setTimeout(() => {
      actionShown.value = true;
    }, 300);
  } else {
    clearTimeout(clickTimeout.value);
  }
};

const outsideHandler = () => {
  clickTimeout.value = null;
  actionShown.value = false;
};

const setZeroQuantityAndDeleteFromRoster = async () => {
  try {
    actionLoading.value = true;
    Axios.get(`${baseURL}/sanctum/csrf-cookie`).then(async () => {
      let response = await Axios.post(`/roster/set-zero/${Props.product.id}`);
      actionLoading.value = false;
      rosterStore.removeItem(response.data.data.item.id);
    });
  } catch (error) {
    actionLoading.value = false;
    console.log(error);
  }
};
const setZeroQuantityAndDoneExtract = async () => {
  try {
    actionLoading.value = true;
    Axios.get(`${baseURL}/sanctum/csrf-cookie`).then(async () => {
      let response = await Axios.post(
        `/roster/extract-all/${Props.product.id}`
      );
      actionLoading.value = false;
      rosterStore.editItem(response.data.data.item);
    });
  } catch (error) {
    actionLoading.value = false;
    console.log(error);
  }
};
const doneExtract = async () => {
  if (
    !actionLoading.value &&
    Props.product.inRoster.status == 0 &&
    (userStore.role == "Super Admin" || userStore.role == "Store Keeper")
  ) {
    try {
      actionLoading.value = true;
      Axios.get(`${baseURL}/sanctum/csrf-cookie`).then(async () => {
        let response = await Axios.post(`/roster/extract/${Props.product.id}`);
        actionLoading.value = false;
        rosterStore.editItem(response.data.data.item);
      });
    } catch (error) {
      actionLoading.value = false;
      console.log(error);
    }
  }
};

const deleteFromRoster = async () => {
  try {
    actionLoading.value = true;
    Axios.get(`${baseURL}/sanctum/csrf-cookie`).then(async () => {
      let response = await Axios.post("/roster", {
        product_id: Props.product.id,
        quantity: 0,
      });
      actionLoading.value = false;
      homeProductsStore.editItem(response.data.data.item);
      rosterStore.editItem(response.data.data.item);
      swal("success", `محصول ${response.data.data.item.name} از لیست حذف شد.`);
    });
  } catch (error) {
    actionLoading.value = false;
    swal("error", "خطا در برقراری ارتباط با سرور.");
  }
};
</script>

<template>
  <div
    class="basis-full lg:basis-1/2 p-2"
    @click="handleClick"
    v-click-outside="outsideHandler"
    @dblclick="doneExtract"
  >
    <div
      class="p-2 rounded-md flex-flex-col relative"
      :class="[
        Props.product.inRoster && Props.product.inRoster.status == 1
          ? 'bg-palette1-yellow-1 bg-opacity-30'
          : 'bg-white',
      ]"
    >
      <div v-if="!actionLoading" class="form-check absolute top-0 left-0">
        <input
          :checked="Props.product.inRoster && Props.product.inRoster.status"
          type="checkbox"
          :id="`product-${Props.product.id}`"
        />
        <span class="custom-check"> </span>
      </div>
      <div
        v-else
        class="absolute top-0 left-0 h-5 w-5 me-2 mt-2 border-2 border-transparent border-t-palette1-purple-2 animate-spin rounded-full"
      ></div>
      <div class="flex flex-row items-center mb-2">
        <img
          @click="
            Props.setImageModalUrl(
              `${Props.product.image}&width=400&height=400`
            )
          "
          :src="`${Props.product.image}&width=50&height=50`"
          :alt="Props.product.name"
          width="50"
          height="50"
        />
        <div class="flex flex-col mx-2 space-y-2 w-full">
          <span class="text-gray-800 text-sm font-bold">{{
            Props.product.name
          }}</span>
          <!-- <div class="w-full flex flex-row justify-start">
                        <span class="basis-1/2 text-gray-400 font-bold text-xs">دسته بندی:<span class="text-red-500">{{
                            Props.product.category ? Props.product.category.name : 'پاک شده' }}</span></span>
                        <span class="basis-1/2 text-gray-400 font-bold text-xs">انبار :<span class="text-red-500">{{
                            Props.product.store ? Props.product.store.name : 'پاک شده' }}</span></span>
                    </div> -->

          <div v-show="true" class="w-full flex flex-row justify-start">
            <span class="basis-1/2 text-gray-400 font-bold text-xs"
              >موجودی‌ انبار:
              <span class="text-red-900 mx-1">{{ quantityOfCartons }} </span>
              کارتن {{ microQuantity > 0 ? "و" : ""
              }}<span v-if="microQuantity > 0" class="text-red-900 mx-1">
                {{ microQuantity }}</span
              >{{ microQuantity > 0 ? "عدد" : "" }}</span
            >
            <span class="basis-1/2 text-gray-400 font-bold text-xs"
              >ثبت شده در لیست:
              <span class="text-red-900 mx-1"
                >{{ quantityOfCartonsInList }}
              </span>
              کارتن {{ microQuantityInList > 0 ? "و" : ""
              }}<span v-if="microQuantityInList > 0" class="text-red-900 mx-1">
                {{ microQuantityInList }}</span
              >{{ microQuantityInList > 0 ? "عدد" : "" }}</span
            >
          </div>
          <div class="w-full flex flex-row justify-start">
            <span class="basis-1/2 text-gray-400 font-bold text-xs"
              >ثبت شده توسط :<span class="text-red-500">{{
                Props.product.inRoster.user
                  ? Props.product.inRoster.user.name
                  : "کاربر پاک شده"
              }}</span></span
            >
          </div>
        </div>
      </div>
      <Transition
        enter-active-class="transition-all duration-300 origin-top"
        leave-active-class="transition-all duration-300 origin-top"
        enter-from-class="scale-y-0"
        enter-to-class="scale-y-100"
        leave-from-class="scale-y-100"
        leave-to-class="scale-y-0"
      >
        <div v-show="actionShown">
          <div class="w-full flex flex-col justify-start my-2">
            <span class="basis-1/2 text-gray-800 font-bold text-xs"
              >ثبت شده:<span class="text-lime-500 font-bold">
                {{ Props.product.inRoster.created_at }}
              </span></span
            >
            <span class="basis-1/2 text-gray-800 font-bold text-xs"
              >ویرایش شده :<span class="text-lime-500 font-bold">
                {{ Props.product.inRoster.updated_at }}
              </span></span
            >
          </div>
          <div
            v-if="
              userStore.role == 'Super Admin' ||
              userStore.role == 'Store Keeper'
            "
          >
            <div v-if="actionLoading" class="flex flex-row justify-center">
              <div class="loading-dots px-6 py-2">
                <div class="loading-dot bg-green-600"></div>
                <div class="loading-dot bg-green-600"></div>
                <div class="loading-dot bg-green-600"></div>
              </div>
            </div>
            <div
              v-else-if="
                Props.product.inRoster && Props.product.inRoster.status == 0
              "
              class="flex flex-row items-center"
            >
              <button
                @click="deleteFromRoster"
                class="mx-2 py-2 px-2 bg-palette1-red-1 rounded-md text-white text-xs"
              >
                حذف
              </button>
              <button
                @click="setZeroQuantityAndDeleteFromRoster"
                class="mx-2 py-2 px-2 bg-palette1-red-2 rounded-md text-white text-xs"
              >
                عدم موجودی
              </button>
              <button
                @click="setZeroQuantityAndDoneExtract"
                class="mx-2 py-2 px-2 bg-palette1-orange-3 rounded-md text-white text-xs"
              >
                بارگیری کل موجودی
              </button>
              <button
                @click="doneExtract"
                class="mx-2 py-2 px-2 bg-palette1-green-2 rounded-md text-white text-xs"
              >
                بارگیری شده
              </button>
            </div>
          </div>
        </div>
      </Transition>
    </div>
  </div>
</template>
