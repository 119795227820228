<script setup>
import { destroyRequest, updateProductRequest } from "@/requests/productRequest";
import { defineProps, ref,computed } from "vue";
import swal from "@/swal";
import useProducts from "@/store/products";
import useHomeProducts from "@/store/homeProducts";

const Props = defineProps(["product", "search", "searchDispatch", 'onDelete', 'onSetZeroEntity', 'setImageModalUrl', 'disableAction', 'setDisableAction','filters']);
const dropDown = ref(false);
const actionLoading = ref(false);
const productsStore = useProducts();
const homeProductsStore = useHomeProducts()

const productName = computed(() => {
    if (Props.search && Props.search.length) {
        let searchItems = Props.search.trim().split(' ');
        let highLightName = Props.product.name;
        searchItems.forEach((searchItem) => {
            highLightName = highLightName.replaceAll(searchItem, `<span class="bg-green-600 text-white inline-block">${searchItem}</span>`)
        })
        return highLightName;
    } else {
        return Props.product.name
    }
});

const closeDropdown = () => {
  dropDown.value = false;
};

const deleteProduct = async () => {
  try {
    Props.setDisableAction(true);
    actionLoading.value = true;
    await destroyRequest(Props.product.id);
    productsStore.fetchData()
    Props.onDelete(Props.product.id);
    Props.setDisableAction(false);
    actionLoading.value = false;
    productsStore.removeItem(Props.product.id);
    homeProductsStore.removeItem(Props.product.id);
    swal('success', 'محصول با موفقیت حذف شد.')
  } catch (error) {
    Props.setDisableAction(false);
    actionLoading.value = false;
    console.log(error)
  }
}

const setZeroEntity = async () => {
  try {
    actionLoading.value = true;
    let response = await updateProductRequest(Props.product.id, {
      name: Props.product.name,
      barcode: Props.product.barcode,
      store: Props.product.store.id,
      category: Props.product.category.id,
      carton_contains: Props.product.carton_contains,
      quantity: 0,
    });
    Props.onSetZeroEntity(Props.product.id);
    actionLoading.value = false;
    productsStore.editItem(response.data.data.product);
    homeProductsStore.removeItem(response.data.data.product);
    swal('success', 'موجودی محصول با موفقیت صفر شد.')
  } catch (error) {
    actionLoading.value = false;
    console.log(error)
  }
}
</script>

<template>
  <tr class="even:bg-slate-50">
    <td class="px-4 py-4 text-sm font-medium whitespace-nowrap">
      <div>
        <span class="font-medium text-gray-800 dark:text-white"  v-html="productName">
        </span>
      </div>
    </td>

    <td @click="Props.setImageModalUrl(`${Props.product.image}&width=400&height=400`)"
      class="px-4 py-4 flex justify-center text-sm font-medium whitespace-nowrap cursor-pointer">
      <img class="rounded-xl" :src="Props.product.image + `&width=50&height=50`" :alt="Props.product.name" width="50">
    </td>

    <td class="px-4 py-4 text-center text-sm font-medium whitespace-nowrap">
      <span class="font-medium text-gray-800 dark:text-white">
        {{ Props.product.barcode }}
      </span>
    </td>

    <td class="px-4 py-4 text-center text-sm font-medium whitespace-nowrap">
      <span class="font-medium text-gray-800 dark:text-white">
        {{ Props.product.store.name }}
      </span>
    </td>

    <td class="px-4 py-4 text-center text-sm font-medium whitespace-nowrap">
      <span class="font-medium text-gray-800 dark:text-white">
        {{ Props.product.category.name }}
      </span>
    </td>

    <td class="px-4 py-4 text-center text-sm font-medium whitespace-nowrap">
      <span class="font-bold text-red-600 dark:text-white">
        {{ Props.product.quantity }}
      </span>
    </td>

    <td class="px-4 py-4 text-sm whitespace-nowrap text-end">
      <div v-if="actionLoading" class="p-1 m-1 bg-gray-400 rounded-md inline-block">
          <div
            class=" animate-spin p-2 bg-gray-300  w-6 h-6 !border-4  !border-b-blue-500 rounded-full top-1/2 right-1/2">
          </div>
        </div>
      <button v-else
      :disabled="Props.disableAction"
        class="relative px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg dark:text-gray-300 bg-gray-100 disabled:bg-gray-400"
        @click="dropDown=true" v-click-outside="closeDropdown" :id="`productIndexRow_${Props.product.id}`">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
          class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round"
            d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
        </svg>
        <Transition enter-active-class="transition-all duration-300 origin-bottom-left"
          leave-active-class="transition-all duration-300 origin-bottom-left" enter-from-class="scale-0"
          enter-to-class="scale-100" leave-from-class="scale-100" leave-to-class="scale-0">
          <div v-show="dropDown"
            class="absolute p-2 bg-white border-2 border-gray-900 rounded-md flex flex-col min-w-[180px] left-8 bottom-[-10px] z-[1000] overflow-y-auto">
            <RouterLink :to="{ name: 'admin.products.edit', params: { id: Props.product.id } }"
              class="w-full px-1 pb-2 flex flex-row justify-between items-center border-b-2 border-gray-300 text-gray-800">
              <span>ویرایش</span>
              <i class="bi bi-gear text-xl"></i>
            </RouterLink>
            <div @click="setZeroEntity"
              class="w-full px-1 py-2 flex flex-row justify-between items-center text-gray-800 border-b-2 border-gray-300">
              <span>صفر کردن موجودی</span>
              <i class="bi bi-0-circle text-xl"></i>
            </div>
            <div @click="deleteProduct" class="w-full px-1 pt-2 flex flex-row justify-between items-center text-gray-800">
              <span>پاک کردن</span>
              <i class="bi bi-trash3-fill text-xl text-red-600"></i>
            </div>
          </div>
        </Transition>
      </button>
    </td>
  </tr>
</template>
