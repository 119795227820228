import axios from "axios";


// ***** prod endPoint *****
    export const baseURL = "https://api.barzegar-shop.ir";
// ******************************


// ***** dev endPoint ******
    // export const baseURL = "http://localhost:8000";
// *********************************

const Axios = axios.create({
    baseURL: `${baseURL}/v1`,
    headers :{
        Accept : 'application/json'
    },
});
Axios.defaults.withCredentials = true;

export default Axios;
