<script setup>
import useHomeProducts from '@/store/homeProducts'
import ProductCartComponent from "@/components/home/ProductCartComponent"
import { ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import useCategories from '@/store/categories';


const homeProductsStore = useHomeProducts();
const categoriesStore = useCategories();
const searchDispatch = ref(true);
const nextPageLoading = ref(false);
const { search, categories: categoriesForFilter, available_products: products,loading } = storeToRefs(homeProductsStore);
const filtersSidebarShown = ref(false);
let searchTimeout = null;


const checkBoxClick = (id) => {
    if (categoriesForFilter.value.includes(id)) {
        categoriesForFilter.value = categoriesForFilter.value.filter(row => row != id);
    } else {
        categoriesForFilter.value = [...categoriesForFilter.value, id]
    }
}

watch(() => search.value, () => {
    searchDispatch.value = false
    if (searchTimeout) {
        clearTimeout(searchTimeout)
    }
    searchTimeout = setTimeout(() => {
        homeProductsStore.filterItems(1);
        searchDispatch.value = true
    }, 700)

})

// image modal
const imageModal = ref(false);
const modalUrl = ref(null);


const showImageModal = (url) => {
    document.body.style.overflow = 'hidden'
    imageModal.value = true,
        modalUrl.value = url;
}

const closeModal = () => {
    imageModal.value = false,
        modalUrl.value = null;
        document.body.style.overflow = 'unset'
}

// _______________________



watch(categoriesForFilter, () => {
    homeProductsStore.filterItems(1);
});

</script>

<template>
    <div v-click-outside="() => { filtersSidebarShown = false }">
        <button @click="() => { filtersSidebarShown = true }"
            class="fixed top-[25vh] left-0 p-2 bg-palette1-green-1 rounded-r-md z-[1001]">
            <i class="bi bi-funnel-fill text-white"></i>
        </button>
        <Transition enter-active-class="transition-all duration-300 origin-left"
            leave-active-class="transition-all duration-300 origin-left" enter-from-class="scale-x-0"
            enter-to-class="scale-x-100" leave-from-class="scale-x-100" leave-to-class="scale-x-0">
            <div v-show="filtersSidebarShown"
                class="fixed p-2 bg-palette1-green-1 text-white min-w-[200px] max-w-[80vw] h-[50vh] top-0 translate-y-[50%] rounded-r-md border-2 border-gray-600 left-0 z-[1010] overflow-y-auto overflow-x-auto">
                <div class="flex flex-row justify-between items-center">
                    <span>دسته بندی :</span>
                    <button @click="() => { filtersSidebarShown = false }">
                        <i class="bi bi-arrow-left text-2xl"></i>
                    </button>
                </div>
                <div class="flex flex-col w-full">
                    <div @click="() => { !homeProductsStore.loading && checkBoxClick(category.id) }" class="form-check"
                        v-for="category in categoriesStore.categories" :key="category.id">
                        <input v-model="categoriesForFilter" :value="category.id" type="checkbox"
                            :id="`category-${category.id}`" :disabled="homeProductsStore.loading">
                        <span class="custom-check"> </span>
                        <label :for="`category-${category.id}`">{{ category.name }}</label>
                    </div>
                </div>
            </div>
        </Transition>
    </div>

    <div class="mt-6 md:flex md:flex-col md:items-start z-[999] bg-gray-100 bg-opacity-80 p-1 rounded-md border-2"
        :class="[(categoriesForFilter.length || search) ? 'sticky top-4' : '']">
        <div class="relative flex items-center mt-2 md:mt-0 mx-4">
            <span class="absolute">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="w-5 h-5 mx-3 text-gray-400 dark:text-gray-600">
                    <path stroke-linecap="round" stroke-linejoin="round"
                        d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                </svg>
            </span>
            <span v-if="!searchDispatch" class="absolute top-0 left-0 flex h-3 w-3">
                <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
                <span class="relative inline-flex rounded-full h-3 w-3 bg-sky-500"></span>
            </span>
            <input v-model.trim="search" type="text" placeholder="جستجو"
                class="block w-full py-1.5 pr-5 text-gray-700 bg-white border border-gray-200 rounded-lg md:w-80 placeholder-gray-400/70 pl-11 rtl:pr-11 rtl:pl-5 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 dark:focus:border-blue-300 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40" />
        </div>
        <div class="max-w-full max-h-[20px] overflow-hidden  text-green-400 text-ellipsis truncate">
            <span v-show="search" class="text-xs font-bold ms-4 inline">جستجوی {{ search }}</span>

            <div v-if="categoriesForFilter.length" class="inline">
                <span class="text-xs font-bold ms-1">در دسته بندی </span>
                <span class="text-xs font-bold" v-for="(category, index) in categoriesForFilter" :key="index">{{
                    categoriesStore.categories.filter(cate => cate.id == category)[0].name }} {{ index
        < categoriesForFilter.length - 1 ? ' , ' : '' }}</span>
            </div>
        </div>
    </div>


    <div v-if="loading" class=" animate-spin absolute w-8 h-8 !border-4  !border-b-blue-500 rounded-full top-1/2 right-1/2">
    </div>
    <div v-else class="w-full flex flex-col md:flex-row flex-wrap md:items-center mb-4">
        <div class="basis-full lg:basis-1/2 p-2" v-for="product in products" :key="product.id">
            <ProductCartComponent :product="product" :search="search" :setImageModalUrl="(url) => showImageModal(url)" />
        </div>
    </div>
    <div v-if="homeProductsStore.next_page != null" class="flex flex-row justify-center mb-12">
        <button @click="homeProductsStore.filterItems(homeProductsStore.next_page)" class="bg-white p-2 rounded-xl text-gray-800 text-sm font-bold disabled:bg-gray-400"
            :disabled="nextPageLoading">نمایش موارد بیشتر</button>
    </div>
    <div v-if="imageModal" class="fixed w-[100vw] h-[100vh] bg-black bg-opacity-50 top-0 right-0 z-[1010]">
        <button @click="closeModal"
            class="border-2 border-white rounded-md absolute top-4 right-4 p-2 bg-gray-800 cursor-pointer">
            <i class="bi bi-x-lg text-3xl text-white"></i>
        </button>
        <img :src="modalUrl"
            class="max-w-[400px] max-h-[400px] right-1/2 top-1/2 absolute translate-x-[50%] translate-y-[-50%]" alt="">
    </div>
</template>