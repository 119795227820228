import Axios from "@/axios";
import axios from "axios";
import { baseURL } from "@/axios";

export const getUser = async (id) => {
  let response;
  response = await Axios.get(`/admin/users/${id}`);

  return response;
};

export const updateRequest = async (id, formData) => {
  let response;
  await Axios.get(`${baseURL}/sanctum/csrf-cookie`).then(() => {
    response = Axios.patch(`/admin/users/${id}`, formData);
  });

  return response;
};

export const deleteUser = async (id) => {
  let response;
  await axios.get(`${baseURL}/sanctum/csrf-cookie`).then(() => {
    response = Axios.delete(`/admin/users/${id}`);
  });

  return response;
};
