<script setup>
import useCategories from '@/store/categories';
import useHomeProducts from '@/store/homeProducts';
import { useRouter } from 'vue-router';

const categoriesStore = useCategories();
const homeProductsStore = useHomeProducts();
const router = useRouter();

const goToHome = (id)=>{
    homeProductsStore.search = '';
    homeProductsStore.categories = [id];
    router.push('/')
}
</script>

<template>
    <div class="flex flex-row flex-wrap justify-center mb-12 w-full">
        <div class="p-2" v-for="category in categoriesStore.categories" :key="category.id">
            <div @click="goToHome(category.id)" class="p-2 bg-white cursor-pointer border-2 border-gray-800 rounded-xl flex flex-col justify-center items-center w-[160px]">
                <img class="rounded-full border-2 border-gray-800" :src="`${category.image}&width=100&height=100`"
                    :alt="category.name" width="100" height="100">
                <span class="my-2 max-w-full text-sm  max-h-[20px] overflow-y-hidden truncate">{{ category.name }}</span>
            </div>
        </div>

    </div>
</template>