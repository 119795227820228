import Axios from "@/axios";
import { defineStore } from "pinia";

const useRoster = defineStore("roster", {
  state: () => {
    return {
      items: [],
      filterItems: [],
      categories: [],
      stores: [],
      shown: "all",
      date: "",
      loading: false,
    };
  },
  actions: {
    async fetchData() {
      try {
        const rosterStore = useRoster();
        this.loading = true;
        let response = await Axios.get(`/roster`);
        this.items = response.data.data.products;
        this.date = response.data.data.date;
        rosterStore.catchFilterItems();
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    catchFilterItems() {
      if (this.categories.length && this.stores.length) {
        this.filterItems = this.items.filter(
          (item) =>
            this.categories.includes(item.category.id) &&
            this.stores.includes(item.store.id) &&
            (this.shown == "pending" ? item.inRoster.status == 0 : true)
        );
      } else if (this.categories.length) {
        this.filterItems = this.items.filter(
          (item) =>
            this.categories.includes(item.category.id) &&
            (this.shown == "pending" ? item.inRoster.status == 0 : true)
        );
      } else if (this.stores.length) {
        this.filterItems = this.items.filter(
          (item) =>
            this.stores.includes(item.store.id) &&
            (this.shown == "pending" ? item.inRoster.status == 0 : true)
        );
      } else {
        this.filterItems = this.items.filter((item) =>
          this.shown == "pending" ? item.inRoster.status == 0 : true
        );
      }

      this.filterItems = this.filterItems.sort((a, b) =>
        a.name.toLowerCase() < b.name.toLowerCase()
          ? 1
          : a.name.toLowerCase() > b.name.toLowerCase()
          ? -1
          : 0
      );
      
      this.filterItems = this.filterItems.sort((a, b) =>
        a.category.name.toLowerCase() < b.category.name.toLowerCase()
          ? 1
          : a.category.name.toLowerCase() > b.category.name.toLowerCase()
          ? -1
          : 0
      );

      this.filterItems = this.filterItems.sort((a, b) =>
        a.store.name.toLowerCase() < b.store.name.toLowerCase()
          ? 1
          : a.store.name.toLowerCase() > b.store.name.toLowerCase()
          ? -1
          : 0
      );

    },
    setCategoriesValue(value) {
      const rosterStore = useRoster();
      this.categories = value;
      rosterStore.catchFilterItems();
    },
    setStoresValue(value) {
      const rosterStore = useRoster();
      this.stores = value;
      rosterStore.catchFilterItems();
    },
    setShownValue(value) {
      const rosterStore = useRoster();
      this.shown = value;
      rosterStore.catchFilterItems();
    },
    editItem(value) {
      const rosterStore = useRoster();
      let index = this.items
        ? this.items.findIndex((product) => product.id == value.id)
        : -1;
      if (value.inRoster && index > -1) {
        this.items[index] = value;
        rosterStore.catchFilterItems();
      } else if (value.inRoster && index == -1) {
        this.items = [value, ...this.items];
        rosterStore.catchFilterItems();
      } else if (!value.inRoster && index > -1) {
        this.items = this.items.filter((product) => product.id != value.id);
        rosterStore.catchFilterItems();
      }
    },
    removeItem(id) {
      const rosterStore = useRoster();
      let index = this.items
        ? this.items.findIndex((product) => product.id == id)
        : -1;
      if (index != -1) {
        this.items = this.items.filter((product) => product.id != id);
        rosterStore.catchFilterItems();
      }
    },
  },
});

export default useRoster;
