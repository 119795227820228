import Axios from "@/axios";
import { defineStore } from "pinia";

const useHomeProducts = defineStore("homeProducts", {
  state: () => {
    return {
      products: [],
      available_products: [],
      per_page: 20,
      current_page: 1,
      next_page: null,
      last_page: 1,
      categories: [],
      search: "",
      loading: false,
    };
  },
  actions: {
    async fetchData() {
      // let stringCategories = this.categories.toString();

      try {
        this.loading = true;
        let response = await Axios.get(`/products`);
        this.products = response.data.data.products;
        this.loading = false;
        this.filterItems();
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    filterItems(page = 1) {
      this.loading = true;
      this.available_products = this.products;
      this.current_page = page;

      // filter items by search string
      if (this.search && this.search.trim().length) {
        let searchItems = this.search.split(" ");
        // if we search one word
        if (searchItems.length === 1) {
          this.available_products = this.available_products.filter(
            (product) => {
              return product.name
                .toUpperCase()
                .includes(searchItems[0].toUpperCase()) ||
                (product.barcode && product.barcode.includes(searchItems[0]))
                ? true
                : false;
            }
          );
        } else {
          // when we search more than one word
          this.available_products = this.available_products.filter(
            (product) => {
              let find = false;
              let multiContains = true;
              searchItems.forEach((str) => {
                if (product.name.includes(str)) {
                  find = true;
                } else {
                  find = false;
                  multiContains = false;
                }
              });

              return find && multiContains;
            }
          );
        }
      }

      // filter items by category
      if (this.categories && this.categories.length) {
        this.available_products = this.available_products.filter((product) => {
          return this.categories.includes(product.category.id);
        });
      }

      // sort items
      this.available_products = this.available_products.sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase()
          ? 1
          : a.name.toLowerCase() < b.name.toLowerCase()
          ? -1
          : 0
      );

      this.available_products = this.available_products.sort((a, b) =>
        a.category.name.toLowerCase() > b.category.name.toLowerCase()
          ? 1
          : a.category.name.toLowerCase() < b.category.name.toLowerCase()
          ? -1
          : 0
      );
      
      this.last_page = Math.ceil(
        this.available_products.length / this.per_page
      );

      this.available_products = this.available_products.slice(
        0,
        this.current_page * this.per_page
      );

      if (this.current_page < this.last_page) {
        this.next_page = this.current_page + 1;
      } else {
        this.next_page = null;
      }

      this.loading = false;
    },
    addItem(product) {
      let index = this.products.findIndex(
        (TempProduct) => TempProduct.id == product.id
      );
      if (index == -1) {
        this.products = [...this.products, product];
        this.filterItems(this.current_page);
      }
    },
    removeItem(id) {
      let findInProducts = this.products.findIndex(
        (product) => product.id == id
      );
      if (findInProducts > -1) {
        this.products = this.products.filter((product) => product.id != id);
        this.filterItems(this.current_page);
      }
    },
    editItem(product) {
      let findInProducts = this.products.findIndex(
        (TempProduct) => TempProduct.id == product.id
      );
      if (findInProducts > -1) {
        this.products[findInProducts] = product;
        this.filterItems(this.current_page);
      }
    },
  },
});

export default useHomeProducts;
