import Axios, { baseURL } from "@/axios";

export const registerRequest = async (formData) => {
  let response;
  await Axios.get(`${baseURL}/sanctum/csrf-cookie`).then(() => {
    response = Axios.post("/register", formData);
  });

  return response;
};
export const loginRequest = async (formData) => {
  let response;
  await Axios.get(`${baseURL}/sanctum/csrf-cookie`).then(() => {
    response = Axios.post("/login", formData);
  });

  return response;
};

export const logoutRequest = async () => {
  let response;
  await Axios.get(`${baseURL}/sanctum/csrf-cookie`).then(() => {
    response = Axios.get("/logout", []);
  });

  return response;
};
