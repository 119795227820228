<script setup>
import { ref, watch } from "vue";
import { indexRequest } from "@/requests/categories";
import TableRowComponent from '@/components/admin/categories/TableRowComponent.vue'
import useCategories from "@/store/categories";
import { storeToRefs } from "pinia";

const categoriesStore = useCategories();
const {categories} = storeToRefs(categoriesStore);
const search = ref("");
const sort = ref('asc');
const searchDispatch = ref(true);
let searchTimeOut = null;


const searchCategory = () => {
  searchDispatch.value = false;
  if (searchTimeOut) {
    clearTimeout(searchTimeOut);
  }

  searchTimeOut = setTimeout(async () => {
    try {
      let response = await indexRequest(sort.value, search.value)
      categories.value = response.data.data.categories;
      searchDispatch.value = true;
    } catch (error) {
      searchDispatch.value = false;
      console.log(error);
    }
  }, 700);
};

const deleteCategory = (id)=>{
  categories.value = categories.value.filter(category=>category.id != id);
}

watch(search, searchCategory);
watch(sort, searchCategory);

</script>

<template>
  <div class="flex flex-row justify-between items-center mx-4">
    <span class="font-bold text-gray-500 text-xl">دسته بندی ها</span>
    <RouterLink :to="{ name: 'admin.categories.create' }"
      class="flex flex-row justify-between items-center bg-white text-gray-500 p-2 rounded-md">
      <span>ایجاد دسته جدید</span>
      <i class="bi bi-person-plus-fill ms-2 mt-1"></i>
    </RouterLink>
  </div>
  <div class="flex flex-row flex-wrap justify-evenly">
    <section class="container px-4 mx-auto">
      <div class="mt-6 md:flex md:items-center md:justify-between z-[999]">
        <div class="relative flex items-center mt-4 md:mt-0">
          <span class="absolute">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
              stroke="currentColor" class="w-5 h-5 mx-3 text-gray-400 dark:text-gray-600">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
            </svg>
          </span>
          <span v-if="search && !searchDispatch" class="absolute top-0 left-0 flex h-3 w-3">
            <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
            <span class="relative inline-flex rounded-full h-3 w-3 bg-sky-500"></span>
          </span>

          <input v-model.trim="search" type="text" placeholder="جستجو"
            class="block w-full py-1.5 pr-5 text-gray-700 bg-white border border-gray-200 rounded-lg md:w-80 placeholder-gray-400/70 pl-11 rtl:pr-11 rtl:pl-5 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 dark:focus:border-blue-300 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40" />
        </div>
      </div>

      <div class="flex flex-col mt-6 mb-12">
        <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div class="overflow-hidden border border-gray-200 dark:border-gray-700 md:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                <thead class="bg-gray-50 dark:bg-gray-800">
                  <tr>
                    <th scope="col"
                      class="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                      <button class="flex items-center gap-x-3 focus:outline-none">
                        <span>نام</span>

                        <svg @click="
                          sort == 'asc' ? (sort = 'desc') : (sort = 'asc')
                          " class="h-3" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M2.13347 0.0999756H2.98516L5.01902 4.79058H3.86226L3.45549 3.79907H1.63772L1.24366 4.79058H0.0996094L2.13347 0.0999756ZM2.54025 1.46012L1.96822 2.92196H3.11227L2.54025 1.46012Z"
                            fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                          <path
                            d="M0.722656 9.60832L3.09974 6.78633H0.811638V5.87109H4.35819V6.78633L2.01925 9.60832H4.43446V10.5617H0.722656V9.60832Z"
                            fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                          <path
                            d="M8.45558 7.25664V7.40664H8.60558H9.66065C9.72481 7.40664 9.74667 7.42274 9.75141 7.42691C9.75148 7.42808 9.75146 7.42993 9.75116 7.43262C9.75001 7.44265 9.74458 7.46304 9.72525 7.49314C9.72522 7.4932 9.72518 7.49326 9.72514 7.49332L7.86959 10.3529L7.86924 10.3534C7.83227 10.4109 7.79863 10.418 7.78568 10.418C7.77272 10.418 7.73908 10.4109 7.70211 10.3534L7.70177 10.3529L5.84621 7.49332C5.84617 7.49325 5.84612 7.49318 5.84608 7.49311C5.82677 7.46302 5.82135 7.44264 5.8202 7.43262C5.81989 7.42993 5.81987 7.42808 5.81994 7.42691C5.82469 7.42274 5.84655 7.40664 5.91071 7.40664H6.96578H7.11578V7.25664V0.633865C7.11578 0.42434 7.29014 0.249976 7.49967 0.249976H8.07169C8.28121 0.249976 8.45558 0.42434 8.45558 0.633865V7.25664Z"
                            fill="currentColor" stroke="currentColor" stroke-width="0.3" />
                        </svg>
                      </button>
                    </th>

                    <th scope="col"
                      class="relative px-4 py-3.5 text-sm text-start font-normal text-gray-500 dark:text-gray-400">
                      <span class="">عملیات</span>
                    </th>
                  </tr>
                </thead>

                <tbody class="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
                  <TableRowComponent v-for="category in categories" :key="category.id" :category="category"
                    :onDelete ="(id)=>{deleteCategory(id)}" :searchDispatch="searchDispatch" :search="search" />
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
