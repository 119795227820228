<script setup>
import { ref, computed, watch } from "vue";
import swal from "@/swal";
import { useRouter } from "vue-router";
import SelectComponent from "@/components/inputs/SelectComponent";
import ImageInput from '@/components/inputs/ImageInput.vue'
import useStore from "@/store/stores";
import useCategories from "@/store/categories";
import Axios, { baseURL } from "@/axios";
import useHomeProducts from "@/store/homeProducts";
import useProducts from "@/store/products";

const router = useRouter();
const homeProductsStore = useHomeProducts();
const productsStore = useProducts();
const stores = computed(() => useStore().stores)
const categories = computed(() => useCategories().categories);

const cartonOfQuantity = ref(null);
const microOfQuantity = ref(null);

const loading = ref(false);
const uploaded = ref(0);
const productData = ref({
  name: null,
  barcode: null,
  store: null,
  category: null,
  carton: null,
  carton_contains: 12,
  quantity: null,
  image: null
});

const validationErrors = ref({
  name: null,
  barcode: null,
  store: null,
  category: null,
  quantity: null,
  carton_contains: null,
  image: null
});

watch(()=>productData.value.quantity, () => {
    if (productData.value.quantity < 0 || typeof (productData.value.quantity) != 'number') {
        productData.value.quantity = 0;
    } else {
        productData.value.quantity = Math.floor(productData.value.quantity);
    }
    cartonOfQuantity.value = Math.floor(productData.value.quantity / productData.value.carton_contains);
    microOfQuantity.value = productData.value.quantity % productData.value.carton_contains;
});
watch(cartonOfQuantity, () => {
    if (cartonOfQuantity.value < 0 || typeof (cartonOfQuantity.value) != 'number') {
        cartonOfQuantity.value = 0;
    } else {
        cartonOfQuantity.value = Math.floor(cartonOfQuantity.value);
    }
    productData.value.quantity = cartonOfQuantity.value * productData.value.carton_contains + microOfQuantity.value
});
watch(microOfQuantity, () => {
    if (microOfQuantity.value < 0 || typeof (microOfQuantity.value) != 'number') {
        microOfQuantity.value = 0;
    } else {
        microOfQuantity.value = Math.floor(microOfQuantity.value);
    }
    productData.value.quantity = cartonOfQuantity.value * productData.value.carton_contains + microOfQuantity.value
});

// onMounted(() => {
//   cartonOfQuantity.value = Math.floor(productData.value.quantity / productData.value.carton_contains);
//   microOfQuantity.value = productData.value.quantity % productData.value.carton_contains;
// })


const submitCreateProduct = () => {
  loading.value = true;
  Axios.get(`${baseURL}/sanctum/csrf-cookie`).then(() => {
    Axios.post("/admin/products/create", productData.value, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (e) => {
        uploaded.value = Math.floor((e.loaded * 100) / e.total);
      }
    }).then(response => {
      loading.value = false;
      uploaded.value = 0;
      if (response.data.status == "success") {
        homeProductsStore.addItem(response.data.data.product);
        productsStore.addItem(response.data.data.product);
        swal("success", `محصول ${productData.value.name} ایجاد شد.`);
        router.replace({ name: "admin.products.index" });
      }
    }).catch(error => {
      loading.value = false;
      uploaded.value = 0;
      if (error.response && error.response.status == 422) {
        validationErrors.value = error.response.data.errors;
      } else {
        console.log(error);
      }
    });
  }).catch(err => {
    console.log(err)
  });
};

</script>

<template>
  <div class="min-w-full flex flex-col justify-start items-start bg-transparent rounded-lg p-4 mb-5">
    <div class="w-full flex flex-row justify-between items-center mb-5">
      <span class="font-bold text-gray-600">ایجاد محصول جدید</span>
      <RouterLink :to="{ name: 'admin.products.index' }"><i
          class="bi bi-arrow-left bg-gray-500 px-2 py-1 text-white text-xl text-center rounded-full"></i></RouterLink>
    </div>
    <div class="mb-4 w-full">
      <div class="form-control bg-white">
        <input v-model="productData.name" id="name" class="input-control"
          :class="{ 'outline-red-500': validationErrors.name }" type="text" placeholder=" " />
        <label for="name" class="label-control">نام محصول</label>
      </div>
      <span class="text-sm font-semibold text-gray-600 px-2 pt-1 block">فرمت مناسب : تست-مایع لباس-2500-آبی(شبنم)</span>
      <span v-if="validationErrors.name" class="invalid">{{
        validationErrors.name[0]
      }}</span>
    </div>
    <div class="mb-4 w-full">
      <div class="form-control bg-white">
        <input v-model="productData.barcode" id="barcode" class="input-control"
          :class="{ 'outline-red-500': validationErrors.barcode }" type="number" placeholder=" " />
        <label for="barcode" class="label-control">بارکد</label>
      </div>
      <span v-if="validationErrors.barcode" class="invalid">{{
        validationErrors.barcode[0]
      }}</span>
    </div>

    <SelectComponent :options="categories" :onChange="(select) => (productData.category = select)"
      :error="validationErrors.category" label='دسته بندی محصول را انتخاب کنید' />
    <SelectComponent :options="stores" :onChange="(select) => (productData.store = select)"
      :error="validationErrors.store" label='انبار قرارگیری محصول را انتخاب کنید' />

    <div class="mb-4 w-full">
      <div class="form-control bg-white">
        <input v-model="productData.carton_contains" id="carton_contains" class="input-control"
          :class="{ 'outline-red-500': validationErrors.carton_contains }" type="number" placeholder=" " />
        <label for="carton_contains" class="label-control">تعداد در کارتن</label>
      </div>
      <span v-if="validationErrors.carton_contains" class="invalid">{{
        validationErrors.carton_contains[0]
      }}</span>
    </div>

    <div class="mb-4 w-full flex flex-row flex-wrap">
      <div class="basis-1/2 px-2">
        <div class="form-control bg-white">
          <input v-model="cartonOfQuantity" id="carton_quantity" class="input-control"
            :class="{ 'outline-red-500': validationErrors.quantity }" type="number" placeholder=" " />
          <label for="carton_quantity" class="label-control">تعداد کارتون</label>
        </div>
      </div>

      <div class="basis-1/2">
        <div class="form-control bg-white">
          <input v-model="microOfQuantity" id="micro_quantity" class="input-control"
            :class="{ 'outline-red-500': validationErrors.quantity }" type="number" placeholder=" " />
          <label for="micro_quantity" class="label-control">تعداد جز</label>
        </div>
      </div>

    </div>

    <div class="mb-4 w-full">
      <div class="form-control bg-white">
        <input v-model="productData.quantity" id="quantity" class="input-control"
          :class="{ 'outline-red-500': validationErrors.quantity }" type="number" placeholder=" " />
        <label for="quantity" class="label-control">تعداد کل</label>
      </div>
      <span v-if="validationErrors.quantity" class="invalid">{{
        validationErrors.quantity[0]
      }}</span>
    </div>

    <div class="mb-4 w-full">
      <ImageInput :onChange="(event) => (productData.image = event.target.files[0])" :error="validationErrors.image" />
    </div>

    <div class="flex flex-row w-full justify-end">
      <button @click="submitCreateProduct" class="px-4 py-2 rounded-md text-white relative min-w-[150px]"
        :class="[loading ? 'bg-gray-400' : 'bg-green-500']" :disabled="loading">
        {{ uploaded != 0 ? uploaded + '%' : 'ایجاد محصول' }}
        <span v-if="uploaded != 0" class="bg-green-500 bg-opacity-80 h-full absolute top-0 left-0 rounded-md"
          :style="{ width: uploaded + '%' }"></span>
      </button>
    </div>
  </div>
</template>
