import Axios from "@/axios";
import { defineStore } from "pinia";

const useUser = defineStore("user", {
  state: () => {
    return {
      loading: false,
      user: JSON.parse(localStorage.getItem("user")) || null,
      role : localStorage.getItem("user") && JSON.parse(localStorage.getItem("user")).roles[0].name || null
    };
  },
  actions: {
    async fetchUser() {
      try {
        this.loading = true;
        let response = await Axios.get("/user");
        this.user = response.data.data.user;
        this.role = response.data.data.role[0].name;
        localStorage.setItem("user", JSON.stringify(this.user));
        this.loading = false;
      } catch (error) {
        if (error.response && error.response.status !== 401) {
          console.log(error);
        }
        this.user = null;
        if (localStorage.getItem("user")) {
          localStorage.removeItem("user");
        }
        this.loading = false;
      }
    },

    clear() {
        this.user = null;
        this.role = null;
        if (localStorage.getItem("user")) {
          localStorage.removeItem("user");
        }
      },

  },

  
});

export default useUser;
