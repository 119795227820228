<script setup>
import { defineProps, ref, watch, computed } from "vue";
import Axios from "@/axios";
import swal from "@/swal";
import useHomeProducts from "@/store/homeProducts";
import useCategories from "@/store/categories";
import useStore from "@/store/stores";
import SelectComponent from "@/components/inputs/SelectComponent.vue";

const Props = defineProps({
  product: {
    required: true,
    type: Object,
  },
  close: {
    required: true,
    type: Function,
  },
});

const stores = computed(() => useStore().stores);
const categories = computed(() => useCategories().categories);

const productName = ref(Props.product.name);
const productBarcode = ref(Props.product.barcode);
const productCategory = ref(Props.product.category.id);
const productStore = ref(Props.product.store.id);
const productCartonContains = ref(Props.product.carton_contains);
const homeProductsStore = useHomeProducts();
const quantityForEdit = ref(Props.product.quantity);
const cartonOfQuantity = ref(
  Math.floor(quantityForEdit.value / Props.product.carton_contains)
);
const microOfQuantity = ref(
  quantityForEdit.value % Props.product.carton_contains
);
const loading = ref(false);

const validationErrors = ref({
  name: null,
  barcode: null,
  store: null,
  category: null,
  quantity: null,
  carton_contains: null,
  image: null,
  imageURL: null,
});

const submitUpdate = async () => {
  try {
    loading.value = true;
    let response = await Axios.patch(`admin/products/${Props.product.id}`, {
      name: productName.value,
      barcode: productBarcode.value || '',
      store: productStore.value,
      category: productCategory.value,
      carton_contains: productCartonContains.value,
      quantity: parseInt(quantityForEdit.value),
    });
    homeProductsStore.editItem(response.data.data.product);
    Props.close();
    loading.value = false;
  } catch (error) {
    if (error.response && error.response.status == 422) {
      swal("error", "بعضی از مقادیر را وارد نکرده اید.");
    } else {
      swal("error", "مشکل در برقراری ارتباط با سرور");
      console.log(error);
    }
  } finally{
    loading.value = false;
  }
};

watch(quantityForEdit, () => {
  if (quantityForEdit.value < 0 || typeof quantityForEdit.value != "number") {
    quantityForEdit.value = 0;
  } else {
    quantityForEdit.value = Math.floor(quantityForEdit.value);
  }
  cartonOfQuantity.value = Math.floor(
    quantityForEdit.value / productCartonContains.value
  );
  microOfQuantity.value = quantityForEdit.value % productCartonContains.value;
});
watch(cartonOfQuantity, () => {
  if (cartonOfQuantity.value < 0 || typeof cartonOfQuantity.value != "number") {
    cartonOfQuantity.value = 0;
  } else {
    cartonOfQuantity.value = Math.floor(cartonOfQuantity.value);
  }
  quantityForEdit.value =
    cartonOfQuantity.value * productCartonContains.value +
    microOfQuantity.value;
});
watch(microOfQuantity, () => {
  if (microOfQuantity.value < 0 || typeof microOfQuantity.value != "number") {
    microOfQuantity.value = 0;
  } else {
    microOfQuantity.value = Math.floor(microOfQuantity.value);
  }
  quantityForEdit.value =
    cartonOfQuantity.value * productCartonContains.value +
    microOfQuantity.value;
});
watch(productCartonContains, () => {
  if (
    productCartonContains.value < 1 ||
    typeof productCartonContains.value != "number"
  ) {
    productCartonContains.value = 1;
  } else {
    productCartonContains.value = Math.floor(productCartonContains.value);
  }
  quantityForEdit.value =
    cartonOfQuantity.value * productCartonContains.value +
    microOfQuantity.value;
});
</script>

<template>
  <div
    class="bg-gray-200 bg-opacity-90 w-full sm:w-[80%] sm:rounded-l-md md:w-[50%] lg:w-[30%] h-full fixed right-0 top-0 flex flex-col z-[1030] text-gray-900 overflow-y-auto"
  >
    <div class="mt-2 mb-2 w-full flex flex-row flex-wrap px-2">
      <div class="flex flex-row justify-between w-full p-2">
        <span class="font-bold">ویرایش محصول</span>
        <button class="font-bold" @click="Props.close">بستن X</button>
      </div>
      <hr class="w-full" />
      <div class="basis-full p-2">
        <div class="form-control bg-white">
          <input
            v-model="productName"
            id="productName"
            class="input-control p-1"
            type="text"
            placeholder=" "
            min="0"
          />
          <label for="productName" class="label-control">نام محصول</label>
        </div>
      </div>
      <div class="basis-full p-2">
        <div @focus="$event.target.select()" class="form-control bg-white">
          <input
            v-model="productBarcode"
            id="productBarcode"
            class="input-control p-1"
            type="number"
            placeholder=" "
            min="0"
          />
          <label for="productBarcode" class="label-control">بارکد</label>
        </div>
      </div>
      <SelectComponent
        :defaultSelect="Props.product.category.id"
        :options="categories"
        :onChange="(select) => (productCategory.value = select)"
        :error="validationErrors.category"
        label="دسته بندی محصول را انتخاب کنید"
      />
      <SelectComponent
        :defaultSelect="Props.product.store.id"
        :options="stores"
        :onChange="(select) => (productStore.value = select)"
        :error="validationErrors.store"
        label="انبار قرارگیری محصول را انتخاب کنید"
      />
      <div class="basis-1/2 p-2">
        <div class="form-control bg-white">
          <input
            @focus="$event.target.select()"
            v-model="productCartonContains"
            id="productCartonContains"
            class="input-control p-1"
            type="number"
            placeholder=" "
            min="0"
          />
          <label for="productCartonContains" class="label-control"
            >تعداد در کارتن</label
          >
        </div>
      </div>
      <div class="basis-1/2 p-2">
        <div class="form-control bg-white">
          <input
            @focus="$event.target.select()"
            v-model="cartonOfQuantity"
            id="cartonOfQuantity"
            class="input-control p-1"
            type="number"
            placeholder=" "
            min="0"
          />
          <label for="cartonOfQuantity" class="label-control"
            >تعداد کارتن</label
          >
        </div>
      </div>
      <div class="basis-1/2 p-2">
        <div class="form-control bg-white">
          <input
            @focus="$event.target.select()"
            v-model="microOfQuantity"
            id="microOfQuantity"
            class="input-control p-1"
            type="number"
            placeholder=" "
            min="0"
          />
          <label for="microOfQuantity" class="label-control"
            >تعداد جز‌‌‌‌ء</label
          >
        </div>
      </div>
      <div class="basis-1/2 p-2">
        <div class="form-control bg-white">
          <input
            @focus="$event.target.select()"
            v-model="quantityForEdit"
            id="quantityForEdit"
            class="input-control p-1"
            type="number"
            placeholder=" "
            min="0"
          />
          <label for="quantityForEdit" class="label-control">تعداد کل</label>
        </div>
      </div>
    </div>
    <div class="w-full flex flex-row flex-wrap px-2 justify-end px-2">
      <button
        @click="submitUpdate"
        class="flex flex-row items-center py-1 px-4 bg-blue-700 disabled:bg-blue-900 disabled:text-gray-400 text-white rounded-md justify-end"
        :disabled="loading"
      >
        <span>ثبت</span>
        <i v-if="!loading" class="bi bi-upload mx-2"></i>
        <div
          v-else
          class="mx-2 animate-spin p-2 bg-blue-900 w-3 h-3 !border-4 !border-gray-400 !border-b-white rounded-full top-1/2 right-1/2"
        ></div>
      </button>
    </div>
    <!-- <button @click="Props.close"
            class="absolute left-0 py-1 px-2 m-1 bg-gray-600 rounded-md font-bold text-white">X</button> -->
  </div>
</template>
