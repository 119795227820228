import Axios from "@/axios";
import { defineStore } from "pinia";

const useProducts = defineStore("products", {
  state: () => {
    return {
      products: [],
      available_products: [],
      current_page_products : [],
      per_page: 20,
      current_page: 1,
      last_page: 1,
      filters: {
        sort: "name",
        sortDirection: "asc",
      },
      loading:false
    };
  },
  actions: {
    async fetchData() {
      try {
        this.loading = true;
        let response = await Axios.get(`admin/products`);
        this.products = response.data.data.products;
        this.loading = false;
        this.filterItems();
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    filterItems(page = 1) {
      this.loading = true;
      this.available_products = this.products;
      this.current_page = page;

      // filter items by search string
      if (this.filters.search && this.filters.search.trim().length) {
        let searchItems = this.filters.search.split(" ");
        // if we search one word
        if (searchItems.length === 1) {
          this.available_products = this.available_products.filter((product) => {
            return product.name.toUpperCase().includes(searchItems[0].toUpperCase()) ||
              (product.barcode && product.barcode.includes(searchItems[0]))
              ? true
              : false;
          });
        } else {
          // when we search more than one word
          this.available_products = this.available_products.filter((product) => {
            let find = false;
            let multiContains = true;
            searchItems.forEach((str) => {
              if (product.name.includes(str)) {
                find = true;
              } else {
                find = false;
                multiContains = false;
              }
            });

            return find && multiContains;
          });
        }
      }

      // filter items by store
      if(this.filters.store && Number.isInteger(this.filters.store)){
        this.available_products = this.available_products.filter(product=>{
          return product.store.id == this.filters.store
        })
      }

        // filter items by category
        if(this.filters.category && Number.isInteger(this.filters.category)){
          this.available_products = this.available_products.filter(product=>{
            return product.category.id == this.filters.category
          })
        }

      // sort items
      if (this.filters.sort) {
        if (
          this.filters.sort === "name" &&
          this.filters.sortDirection === "desc"
        ) {
          this.available_products = this.available_products.sort((a, b) =>
            a.name.toLowerCase() < b.name.toLowerCase()
              ? 1
              : a.name.toLowerCase() > b.name.toLowerCase()
              ? -1
              : 0
          );
        } else if (
          this.filters.sort === "name" &&
          this.filters.sortDirection === "asc"
        ) {
          this.available_products = this.available_products.sort((a, b) =>
            a.name.toLowerCase() > b.name.toLowerCase()
              ? 1
              : a.name.toLowerCase() < b.name.toLowerCase()
              ? -1
              : 0
          );
        }else if (
          this.filters.sort === "store" &&
          this.filters.sortDirection === "desc"
        ) {
          this.available_products = this.available_products.sort((a, b) =>
            a.store.name.toLowerCase() < b.store.name.toLowerCase()
              ? 1
              : a.store.name.toLowerCase() > b.store.name.toLowerCase()
              ? -1
              : 0
          );
        } else if (
          this.filters.sort === "store" &&
          this.filters.sortDirection === "asc"
        ) {
          this.available_products = this.available_products.sort((a, b) =>
            a.store.name.toLowerCase() > b.store.name.toLowerCase()
              ? 1
              : a.store.name.toLowerCase() < b.store.name.toLowerCase()
              ? -1
              : 0
          );
        }else if (
          this.filters.sort === "category" &&
          this.filters.sortDirection === "desc"
        ) {
          this.available_products = this.available_products.sort((a, b) =>
            a.category.name.toLowerCase() < b.category.name.toLowerCase()
              ? 1
              : a.category.name.toLowerCase() > b.category.name.toLowerCase()
              ? -1
              : 0
          );
        } else if (
          this.filters.sort === "category" &&
          this.filters.sortDirection === "asc"
        ) {
          this.available_products = this.available_products.sort((a, b) =>
            a.category.name.toLowerCase() > b.category.name.toLowerCase()
              ? 1
              : a.category.name.toLowerCase() < b.category.name.toLowerCase()
              ? -1
              : 0
          );
        }
      }

      this.current_page_products = this.available_products.slice(this.current_page*this.per_page-this.per_page,this.current_page*this.per_page)
      this.last_page = Math.ceil(this.available_products.length / this.per_page);
      this.loading = false;
    },
    addItem(product){
      let index = this.products.findIndex(
        (TempProduct) => TempProduct.id == product.id
      );
      if (index == -1) {
        this.products = [...this.products, product];
        this.filterItems(this.current_page);
      }
    },
    removeItem(id) {
      let index = this.products.findIndex((product) => product.id == id);
      if (index > -1) {
        this.products = this.products.filter((product) => product.id != id);
        this.filterItems(this.current_page);
      }
    },
    editItem(product) {
      let index = this.products.findIndex(
        (TempProduct) => TempProduct.id == product.id
      );
      if (index > -1) {
        this.products[index] = product;
        this.filterItems(this.current_page)
      }
    },
  },
});

export default useProducts;
