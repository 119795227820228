import Axios, { baseURL } from "@/axios";
import axios from "axios";

export const createProductRequest = async (formData) => {
  let response;
  await axios.get(`${baseURL}/sanctum/csrf-cookie`).then(() => {
    response = Axios.post("/admin/products/create", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  });

  return response;
};

export const updateProductRequest = async (id,formData) => {
  let response;
  await axios.get(`${baseURL}/sanctum/csrf-cookie`).then(() => {
    response = Axios.post(`/admin/products/${id}`, {_method:'PATCH',...formData}, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  });

  return response;
};


export const indexRequest = async () => {
  return await Axios.get("/admin/products");
};

export const destroyRequest = async (id) => {
  let response;
  await axios.get(`${baseURL}/sanctum/csrf-cookie`).then(() => {
    response = Axios.delete(`/admin/products/${id}`);
  });

  return response;
};

export const getProductRequest = async (id)=>{
  return await Axios.get(`/admin/products/${id}`);
}
