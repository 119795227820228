<script setup>
import { ref, defineProps } from "vue";
import useStore from '@/store/stores';
import swal from "@/swal";
import { deleteStoreRequest } from "@/requests/storeRequest";

const destroyLoading = ref(false);
const destroyModal = ref(false);
const storeStore = useStore();
const Props = defineProps({
  store: {
    type: Object,
    required: true,
  },
});

const openDestroyModal = () => {
  document.body.style.overflow = 'hidden'
  destroyModal.value = true;
}

const closeDestroyModal = () => {
  document.body.style.overflow = 'unset'
  destroyModal.value = false;
}

const destroyCategory = async () => {
  try {
    destroyLoading.value = true;
    let response = await deleteStoreRequest(Props.store.id);
    if (response.data.status == "success") {
      storeStore.removeStore(Props.store.id);
      destroyLoading.value = false;
      swal('success', `انبار ${Props.store.name} با موفقیت حذف شد.`);
      storeStore.removeStore(response.data.data.store.id)
    }
  } catch (error) {
    console.log(error);
  }
};
</script>

<template>
  <tr class="even:bg-slate-50">
    <td>
      <div class="w-full flex flex-row items-center px-2">
        <span class="font-medium text-gray-800 dark:text-white ms-2">
          {{ Props.store.name }}
        </span>
      </div>
    </td>
    <td>
      <div class="flex flex-row justify-end items-center space-x-4 px-4">
        <div class="py-2">
          <i v-if="destroyLoading"
            class="bi bi-gear-fill text-xl text-gray-700 py-1 px-2 m-2 bg-gray-300 rounded-md cursor-pointer"></i>
          <RouterLink v-else :to="{ name: 'admin.stores.edit', params: { id: store.id } }">
            <i class="bi bi-gear-fill text-xl text-gray-700 py-1 px-2 m-2 bg-gray-300 rounded-md cursor-pointer"></i>
          </RouterLink>
        </div>
        <div class="py-2">
          <i @click="openDestroyModal"
            class="bi bi-trash-fill text-xl text-red-500 py-1 px-2 m-2 bg-gray-300 rounded-md cursor-pointer"></i>

        </div>

      </div>
    </td>
  </tr>
  <div v-if="destroyModal" class="w-[100vw] h-[100vh] fixed top-0 right-0 bg-black bg-opacity-50 z-[1010]">

    <div
      class="absolute px-4 min-w-[340px] w-[50%] min-h-[200px] top-8 left-1/2 bg-white translate-x-[-50%] rounded-md d-flex flex-col items-center justify-center">
      <div class="w-full flex justify-center">
        <img src="../../../assets/danger.png" alt="danger" width="200" height="200">
      </div>
      <span class=""><span class="font-bold text-red-500">هشدار :</span>با حذف انبار تمام محصولات موجود در این انبار حذف
        خواهند شد.آیا از درخواست خود مطمئن هستید ؟</span>
      <div class="w-full flex flex-row justify-around p-2">
        <button class="bg-green-400 p-2 rounded-md" @click="closeDestroyModal">انصراف</button>
        <button class="bg-red-500 p-2 rounded-md flex items-center" @click="destroyCategory">بله،حذف بشه 
          <div v-if="destroyLoading"
            class="inline-block animate-spin p-2 bg-transparent  w-6 h-6 !border-4 border-transparent  !border-b-white rounded-full top-1/2 right-1/2 ms-2">
          </div></button>
      </div>
    </div>

  </div>
</template>
