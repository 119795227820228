<script setup>
import { defineProps, ref, watch, watchEffect } from "vue";

const Props = defineProps({
  options: Object,
  onChange: Function,
  label: String,
  labelDisable:{
    type:Boolean,
    default:true,
  },
  error: Array,
  defaultSelect: { type: Number, default: null },
});
const select = ref(null);

watchEffect(()=>select.value = Props.defaultSelect);
watch(select, () => Props.onChange(select.value == "null" ? '' : select.value ));

</script>

<template>
  <div class="mb-4 w-full">
    <div class="form-control bg-white">
      <select
        v-model="select"
        class="input-control"
        :class="{ 'outline-red-500': Props.error }"
        placeholder=" "
      >
        <option value="null" :selected="defaultSelect == null" :disabled="labelDisable">
          {{ Props.label }}
        </option>
        <option
          v-for="option in Props.options"
          :key="option.id"
          :value="option.id"
          :selected="defaultSelect == option.id"
        >
          {{ option.name }}
        </option>
      </select>
    </div>
    <span v-if="Props.error" class="invalid">{{ Props.error[0] }}</span>
  </div>
</template>
