<script setup>
import Axios from "@/axios";
import { ref, watch } from "vue";
import TableRowComponent from "@/components/admin/users/TableRowComponent"



const users = ref([]);
const fetchLoading = ref(false);
const more = ref(null);
const moreLoading = ref(false);
const search = ref("");
const searchDispatch = ref(false);
const sort = ref("asc");
let searchTimeOut = null;

const fetchUsers = async () => {
  try {
    fetchLoading.value = true;
    let response = await Axios.get(
      `/admin/users?search=${search.value}&sort=${sort.value}`
    );
    users.value = response.data.data.users;
    more.value = response.data.data.links.next;
    fetchLoading.value = false;
  } catch (error) {
    fetchLoading.value = false;
    console.log(error);
  }
};

const showMore = async () => {
  try {
    moreLoading.value = true;
    let response = await Axios.get(
      `${more.value}&search=${search.value}&sort=${sort.value}`
    );
    users.value = [...users.value, ...response.data.data.users];
    more.value = response.data.data.links.next;
    moreLoading.value = false;
  } catch (error) {
    moreLoading.value = false;
    console.log(error);
  }
};

const searchUser = () => {
  searchDispatch.value = false;
  if (searchTimeOut) {
    clearTimeout(searchTimeOut);
  }

  searchTimeOut = setTimeout(async () => {
    try {
      let response = await Axios.get(
        `/admin/users?search=${search.value}&sort=${sort.value}`
      );
      users.value = response.data.data.users;
      more.value = response.data.data.links.next;
      searchDispatch.value = true;
    } catch (error) {
      searchDispatch.value = false;
      console.log(error);
    }
  }, 700);
};

const userDelete = (id) => {
  users.value = users.value.filter((user) => user.id != id);
};

fetchUsers();

watch(search, searchUser);
watch(sort, fetchUsers);
</script>

<template>
  <div class="flex flex-row justify-between items-center mx-4">
    <span class="font-bold text-gray-500 text-xl">کاربران</span>
    <RouterLink :to="{ name: 'admin.users.create' }"
      class="flex flex-row justify-between items-center bg-white text-gray-500 p-2 rounded-md">
      <span>ایجاد کاربر جدید</span>
      <i class="bi bi-person-plus-fill ms-2 mt-1"></i>
    </RouterLink>
  </div>
  <section class="container px-4 mx-auto">
    <div class="mt-6 md:flex md:items-center md:justify-between z-[999]">
      <div class="relative flex items-center mt-4 md:mt-0">
        <span class="absolute">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
            class="w-5 h-5 mx-3 text-gray-400 dark:text-gray-600">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
          </svg>
        </span>
        <span v-if="search && !searchDispatch" class="absolute top-0 left-0 flex h-3 w-3">
          <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
          <span class="relative inline-flex rounded-full h-3 w-3 bg-sky-500"></span>
        </span>

        <input v-model.trim="search" type="text" placeholder="جستجو"
          class="block w-full py-1.5 pr-5 text-gray-700 bg-white border border-gray-200 rounded-lg md:w-80 placeholder-gray-400/70 pl-11 rtl:pr-11 rtl:pl-5 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 dark:focus:border-blue-300 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40" />
      </div>
    </div>

    <div class="flex flex-col mt-6 mb-12">
      <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div class="overflow-hidden border border-gray-200 dark:border-gray-700 md:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
              <thead class="bg-gray-50 dark:bg-gray-800">
                <tr>
                  <th @click="
                    sort == 'asc' ? (sort = 'desc') : (sort = 'asc')
                    " scope="col"
                    class="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <button class="flex items-center gap-x-3 focus:outline-none">
                      <span>نام</span>

                        <i v-if="sort == 'asc'" class="bi bi-sort-alpha-down text-xl ms-2"></i>
                        <i v-else class="bi bi-sort-alpha-down-alt text-xl ms-2"></i>
                    </button>
                  </th>

                  <th scope="col"
                    class="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    کاربری
                  </th>

                  <th scope="col"
                    class="relative px-4 py-3.5 text-sm text-end font-normal text-gray-500 dark:text-gray-400">
                    <span class="">عملیات</span>
                  </th>
                </tr>
              </thead>
              <div v-if="fetchLoading"
                class=" animate-spin absolute w-8 h-8 !border-4  !border-b-blue-500 rounded-full top-1/2 right-1/2"></div>
              <tbody v-else class="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
                <TableRowComponent v-for="user in users" :key="user.id" :user="user" :search="search"
                  :searchDispatch="searchDispatch" :onDelete="(id) => userDelete(id)" />
              </tbody>
            </table>
            <div @click="showMore" v-if="more && !fetchLoading" class="flex my-2 justify-center w-full">
              <button class="text-sm bg-white text-gray-500 p-2 rounded-md relative">
                <span>نمایش بیشتر</span>
                <span v-if="moreLoading" class="absolute top-0 left-0 flex h-3 w-3">
                  <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
                  <span class="relative inline-flex rounded-full h-3 w-3 bg-sky-500"></span>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
